import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  Card as MyCard,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';

import {
  ApplyAutomaticCoupon,
  ApplyCoupon,
  cardPointePayment,
  cardPointePaymentByProfileId,
  checkUser,
  getBalance,
  getCustomizationOption,
  getGivexCardDetail,
  getGivexDetails,
  getSavedCardDetail,
  getStoreStartendTime,
  givexCardPayment,
  makeDefaultCard,
  PlaceOrder
} from "../../Api/API";
import AlertPopup from "../../Common/AlertPopup";
import ButtonLoader from "../../Common/ButtonLoader";
import TabBar from "../../Common/Components/Navbar/TabBar";
import {
  AmPmFormat,
  errorToastMessage,
  handlePlaceOrderWithTime,
  handleStoreStartEndTimeSec,
  MenuProps,
  numberWithCommas,
  TwentyFourHourFormat,
} from "../../Common/utils";
import {
  setCartData,
  setCartDetailes,
  setCartItemIndexs,
  setCustomizeOpen,
  setEditTitles,
  setOpenPopUps,
  setOrderTotalPrice,
  setPaymentData,
  setPickUpLaterTime,
  setPlacedOrder,
  setSelectedCupSizes,
  setTabValue,
  setTitles,
  setUpdateCart,
} from "../../Redux/CartSlice";
import { setGivexPaymentDetail } from "../../Redux/StoreSlice";
import {
  isGuestLogin,
  isLogin,
  setAutoDiscountDetail,
  setCheckoutFlag,
  setDefaultCardd,
  setDiscountDetail,
  setFiveStarIndex,
  setFiveStarResponse,
  setFiveStarRewardDetail,
  setGivexDetail,
  setIsCheckout,
  setRemoveReward,
} from "../../Redux/UserSlice";
import Popup from "../Categories/CategoryPopUp";
import Guest from "../Login/Guest";
import Login from "../Login/Login";
import GiftCard from "../Payment/GiftCard";
import AddCard from "../Setting.js/AddCard";
import Card from "./../../Common/Card";
import RewardsPopup from "./RewardsPopup";
toast.configure();
const useStyle = makeStyles((theme) => ({
  filledRoot: {
    paddingTop: "0rem",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  emptyRoot: {
    paddingBottom: "5rem",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    overflow: "hidden",
  },
  favImgTran: {
    width: "30%",
    objectFit: "contain",
    transition: "transform 0.15s ease-in-out",
  },
  emptyCartMessage: {
    color: "#C60734",
    fontSize: "2rem",
    fontWeight: "bold",
    marginBottom: "1.25rem",
    fontFamily: "Lusitana",
  },
  addItemsButton: {
    marginTop: "1rem",
    backgroundColor: "#C60734",
    color: "white",
    borderRadius: "20px",
    padding: "0.5rem 2rem",
    textTransform: "none",
    fontFamily: "Lusitana",
    marginBottom: "15rem",
  },
  noBorder: {
    border: "none",
  },
  bill: {
    paddingTop: "13vh",
  },
  billOrientation: {
    display: "flex",
    justifyContent: "space-between",
  },
  location: { display: "flex", marginTop: "1rem", marginBottom: "1.4rem" },
  promo: {
    display: "flex",
    marginTop: "2rem",
    justifyContent: "space-between",
  },
  divider: {
    backgroundColor: "#d9e2ee",
    marginBottom: "0.8rem",
  },
  btnLogin: {
    borderRadius: 20,
    padding: "0.2rem 3rem",
    backgroundColor: "brown",
    color: "white",
    textTransform: "none",
  },
  btnLoginBack: {
    padding: "0.2rem 1rem",
    backgroundColor: "brown",
    color: "white",
    textTransform: "none",
    marginTop: "1rem",
    marginBottom: "0.2rem",
  },
  btnLoginChekout: {
    borderRadius: 20,
    padding: "0.2rem 6rem",
    backgroundColor: "brown",
    textTransform: "none",
    marginTop: "2rem",
  },
  buttons: { display: "flex", justifyContent: "center", marginTop: "2rem" },
  guest: {
    display: "flex",
    justifyContent: "center",
    marginTop: "0.5rem",
    cursor: "pointer",
    transition: "transform 0.10s ease-in-out",
    "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
  },
  error: {
    color: "#bf1650",
    textAlign: "center",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
  errorS: {
    color: "green",
    textAlign: "center",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
  name: {
    fontWeight: 600,
    color: "#122740",
    fontFamily: "Barlow, san-serif",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  caption: {
    fontSize: "0.875rem",
    color: "#758392",
    marginTop: -4,
  },

  qty: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  tabBarContainer: {
    width: "100%",
    backgroundColor: "#fff",
  },
}));

const PersonItem = ({
  item,
  listKey,
  setDisc,
  setPromoApply,
  setPromoApplyError,
  setPromoCode,
  setTotalFivestar,
  setUpdateBtnFlag,
}) => {
  const [remove, setRemove] = useState(false);
  const nearestStore = useSelector((state) => state.store.nearestStore);
  const [counter, setCounter] = useState(item?.quantity);
  const userDetail = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [displayTitle, setDisplayTitle] = useState("");
  const dispatch = useDispatch();
  const promoCode = useSelector((state) => state.user.discountDetail);
  const fiveStarDiscount = useSelector((state) => state.user.fiveStarResponse);
  const cartItem = useSelector((state) => state.cart.cart);

  let cartDetail = {
    id: item?.id,
    categoryId: item?.categoryId,
    categoryName: item?.categoryName,
    customizationOpt: item?.customizationOpt,
    quantity: Number(counter),
    price: item?.price,
    image: item?.image,
    title: item?.title,
    sku_code:item?.sku_code,
    totalPrice: item?.price * counter,
    btnModifierId: item?.btnModifierId,
    btnModifier: item?.btnModifier,
    selectedOptions: item?.selectedOptions,
    menuServingDetail: item?.menuServingDetail,
    selectedCupSize: item?.selectedCupSize,
    orderItemPrice: item?.orderItemPrice,
    max_calories: 0,
    min_calories: 0,
    itemAliasName: item?.itemAliasName,
    toppings_counter_limit:item?.toppings_counter_limit,
  };
  const handleCustomizationCondition = () => {
    const hasOnlyOneServing = item?.selectedCupSize?.length === 1 &&
      item?.selectedCupSize[0]?.serving_name === "Each";
    const hasNoModifiers = !item?.customizationOpt || item?.customizationOpt?.length === 0;

    if (!hasOnlyOneServing || !hasNoModifiers) {
      setUpdateBtnFlag(false);
      if (!promoCode && !fiveStarDiscount) {
        handleCustomization();
      } else {
        setRemove(true);
        setTitle("removeDiscount");
        setDisplayTitle("Editing your cart will remove the discount applied");
      }
    }
  };

  useEffect(() => {
    if (!remove && title === "removeDiscount") {
      handleCustomization();
    }
  }, [remove]);

  const handleCustomization = () => {
    setLoading(true);
    let data = {
      customerId: userDetail?.customer_id
        ? userDetail?.customer_id
        : userDetail?.person_id,
      menuId: item?.id ? item?.id : 0,
      storeId: nearestStore?.id ? nearestStore?.id : 1,
      appType: "web",
    };

    getCustomizationOption(data)
      .then((res) => {
        cartDetail.max_calories = res?.result[0][0]?.max_calories;
        cartDetail.min_calories = res?.result[0][0]?.min_calories;
        cartDetail.is_favourite = res?.result[0][0]?.is_favourite;
        cartDetail.cold = res?.result[0][0]?.cold;
        cartDetail.caffine = res?.result[0][0]?.caffeine;
        cartDetail.description = res?.result[0][0]?.description;
        cartDetail.menuServingDetail = res?.result[0][0]?.menu_serving_detail;
        cartDetail.selectedCupSize = [item?.selectedCupSize[0]];
        cartDetail.customizationOpt = res?.result[0][0]?.customizationOpt;

        dispatch(setCartDetailes(cartDetail));
        dispatch(setOpenPopUps(true));
        dispatch(setTitles("editFromCart"));
        dispatch(setEditTitles("FromCart"));
        dispatch(setSelectedCupSizes(cartDetail?.selectedCupSize));
        dispatch(setCartItemIndexs(listKey));
        dispatch(setCustomizeOpen(true));
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        errorToastMessage();
      });
  };

  useEffect(() => {
    if (counter === 0) {
      const updatedCart = [...cartItem];
      updatedCart.splice(listKey, 1);
      dispatch(setUpdateCart(updatedCart));
      if (updatedCart.length === 0) {
        window.scrollTo(0, 0);
      }
    } else {
      const item = cartDetail;
      const updatedCart = [...cartItem];
      updatedCart.splice(listKey, 1, item);
      dispatch(setUpdateCart(updatedCart));
    }
  }, [counter]);
  useEffect(() => {
    setCounter(item?.quantity);
    dispatch(setRemoveReward(""));
  }, [cartItem]);

  const handleRemove = () => {
    setRemove(true);
    setTitle("removeItem");
    setDisplayTitle("Are you sure want to remove this item from your cart?");
  };

  const handleRemoveCartItem = () => {
    setRemove(true);
    setTitle("removeItem");
    setDisplayTitle("Are you sure want to remove this item from your cart?");
  };
  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container>
        <Grid item md={2} xs={3} sm={2} lg={2}>
          <div>
            <Box
            sx={{
              height: "6rem",
              width: { lg: "5rem", md: "4.5rem", sm: "6rem", xs: "4rem" },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",    
            }}
          >
            <Box
              component="img"
              sx={{
                height: "6rem",
                cursor: "default",
                justifyContent: "end",
                maxWidth: { lg: "7rem", md: "4.5rem", sm: "6rem", xs: "4rem" },
              }}
              alt="menuImage"
              src={item?.image ? item?.image : "./logos.png"}
              onClick={"disable"}
            />
            </Box>
          </div>
        </Grid>
        <Grid
          item
          md={7}
          xs={7}
          sm={7}
          lg={7}
          display="flex"
          direction="column"
          justifyContent="start"
        >
          <Typography variant="subtitle" color="#4D4D4D" fontWeight="600">
            {item.title}
          </Typography>
          <div style={{ display: "flex", color: "#4D4D4D" }}>
            <Typography>
              {item?.selectedCupSize?.length > 0 ? "" : " "}
            </Typography>
            <Typography>{item?.selectedCupSize[0]?.serving_name}</Typography>
          </div>
          <Typography variant="subtitle2" style={{ color: "#797979" }}>
            {item?.selectedOptions?.map(
              (elm, index) =>
                (elm?.option?.length > 0
                  ? elm?.option?.map((item) => {
                    return (
                      " " +
                      item?.modifier_name +
                      (item?.quantity > 1
                        ? " (" + item?.quantity + ") "
                        : " ")
                    );
                  })
                  : " ") +
                (item?.selectedOptions?.length - 1 != index ? "| " : " ")
            )}
          </Typography>
          <div style={{ display: "flex", color: "#4D4D4D" }}>
            <Typography>
              <b>{`$${numberWithCommas(
                Number(item?.orderItemPrice * item?.quantity).toFixed(2)
              )}`}</b>
            </Typography>
          </div>
          <div style={{ display: "flex" }}>
              <button
                onClick={handleCustomizationCondition}
                style={{
                  textDecoration: "underline",
                  backgroundColor: "#FFFFFF",
                  marginLeft: "-.4rem",
                  fontSize: ".8rem",
                  color: "#C60734",
                  border: "none",
                opacity: (item?.selectedCupSize[0]?.serving_name === "Each" && !item?.customizationOpt?.length) ? 0.5 : 1,
                cursor: (item?.selectedCupSize[0]?.serving_name === "Each" && !item?.customizationOpt?.length) ? "not-allowed" : "pointer"
              }}
              disabled={item?.selectedCupSize[0]?.serving_name === "Each" && !item?.customizationOpt?.length}
            >
              Edit
            </button>
            <button
              onClick={handleRemoveCartItem}
              style={{
                textDecoration: "underline",
                backgroundColor: "#FFFFFF",
                marginLeft: ".4rem",
                fontSize: ".8rem",
                color: "#C60734",
                border: "none",
              }}
            >
              Remove
            </button>
          </div>
        </Grid>
        <Grid
          item
          md={3}
          xs={2}
          sm={3}
          lg={3}
          display="flex"
          justifyContent="center"
          alignItems="center"
        // gap={1}
        // sx={{
        //   flexDirection: { xs: "column", sm: "row" },
        // }}
        >
          {counter == 1 ? (
            <DeleteOutlineOutlinedIcon
              sx={{ cursor: "pointer", fontSize: { xs: "1.5rem", sm: "2rem" } }}
              onClick={() => handleRemove()}
            />
          ) : (
            <RemoveOutlinedIcon
              sx={{ cursor: "pointer", fontSize: { xs: "1.5rem", sm: "2rem" } }}
              onClick={() => setCounter(counter - 1)}
            />
          )}
          <Box>
            <Typography
              sx={{
                backgroundColor: "#C60734",
                color: "white",
                fontSize: { xs: ".8rem", sm: "1rem" },
                padding: "0.1rem 0rem",
                borderRadius: "4px",
                textAlign: "center",
                minWidth: { xs: "1rem", sm: "2rem", md: "3rem" },
              }}
            >{counter}</Typography>
          </Box>
          <AddIcon
            sx={{ cursor: "pointer", fontSize: { xs: "1.5rem", sm: "2rem" } }}
            onClick={() => {
              setCounter(counter + 1);
            }}
          />
          {/* </div> */}
        </Grid>
      </Grid>
      <div>
        <AlertPopup
          open={remove}
          setOpen={setRemove}
          setCounter={title === "removeDiscount" ? setTitle : setCounter}
          setDisc={setDisc}
          setCupSizeState={setPromoApply}
          setFavState={setPromoCode}
          setCurrentLocation={setPromoApplyError}
          setTotalFivestar={setTotalFivestar}
          title={title}
          buttonType1={true}
          buttonType2={true}
          displayTitle={displayTitle}
        />
      </div>
    </>
  );
};

const Cart = () => {
  const classes = useStyle();
  const [time, setTime] = React.useState();
  const [totalFivestar, setTotalFivestar] = React.useState();
  const [fivestarDiscount, setfivestarDiscount] = useState(totalFivestar);
  const handleChange = (event) => {
    setTime(event.target.value);
  };
  let navigate = useNavigate();
  const containerRef = useRef();
  const tax = useSelector((state) => state.cart.taxes);
  const taxes = tax?.tax_rate;
  const webFee = tax?.web_fee;
  const [remove, setRemove] = useState(false);
  const cartItem = useSelector((state) => state.cart.cart);
  const cartData = useSelector((state) => state.cart.cartData);
  const fiveStarResponse = useSelector((state) => state.user.fiveStarResponse);
  const dispatch = useDispatch();
  const pickUpLaterTime = useSelector((state) => state.cart.pickUpLaterTime);
  const customizeOpen = useSelector((state) => state.cart.customizeOpen);
  const isLogin = useSelector((state) => state.user.isLogin);
  const isGuestLogin = useSelector((state) => state.user.isGuestLogin);
  const user = useSelector((state) => state.user.user);
  const storeName = useSelector((state) => state.store.nearestStore);
  const [savedCards, setSavedCard] = useState([]);
  const [savedGivxCards, setGivxSavedCard] = useState(false);
  const [open, setOpen] = useState(false);
  const userDetail = useSelector((state) => state.user.user);
  const defaultCard = useSelector((state) => state.user.defaultCardd);
  const [GiftCardSelect, setGiftCardSelect] = useState(false);
  const [GIFTCard, setGIFTCard] = useState();
  const [showCard, setshowCard] = useState(false);
  const [showText, setShowText] = useState(false);
  const [giftCardAmount, setGiftCardAmount] = useState(0);
  const [giftCard, setGiftCard] = useState(false);
  const [givexCardDetails, setGivexCardDetails] = useState(true);
  const [boolBalanceFetched, setBoolBalanceFetched] = useState(false);
  const [cardDataSave, setCartDataSave] = useState();
  const [pickUpLaterPopup, setPickUpLaterPopup] = useState(false);
  const [checkOutbuttonDisabled, setChecoutButtonDisabled] = useState(false);
  const [showCombineDiscountPopup, setShowCombineDiscountPopup] =
    useState(false);
  const [giftCardPaymentDetail, setGiftCardPaymentDetail] = useState({
    cardNumber: "",
    payment: "",
    requestId: "",
  });
  const [availableBalance, setAvailableBalance] = useState();
  const [givexCardExist, setGivexCardExist] = useState();
  const [alwaysPromoApply, setAlwaysPromoApply] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const timerange = [
    "00:00",
    "00:15",
    "00:30",
    "00:45",
    "01:00",
    "01:15",
    "01:30",
    "01:45",
    "02:00",
    "02:15",
    "02:30",
    "02:45",
    "03:00",
    "03:15",
    "03:30",
    "03:45",
    "04:00",
    "04:15",
    "04:30",
    "04:45",
    "05:00",
    "05:15",
    "05:30",
    "05:45",
    "06:00",
    "06:15",
    "06:30",
    "06:45",
    "07:00",
    "07:15",
    "07:30",
    "07:45",
    "08:00",
    "08:15",
    "08:30",
    "08:45",
    "09:00",
    "09:15",
    "09:30",
    "09:45",
    "10:00",
    "10:15",
    "10:30",
    "10:45",
    "11:00",
    "11:15",
    "11:30",
    "11:45",
    "12:00",
    "12:15",
    "12:30",
    "12:45",
    "13:00",
    "13:15",
    "13:30",
    "13:45",
    "14:00",
    "14:15",
    "14:30",
    "14:45",
    "15:00",
    "15:15",
    "15:30",
    "15:45",
    "16:00",
    "16:15",
    "16:30",
    "16:45",
    "17:00",
    "17:15",
    "17:30",
    "17:45",
    "18:00",
    "18:15",
    "18:30",
    "18:45",
    "19:00",
    "19:15",
    "19:30",
    "19:45",
    "20:00",
    "20:15",
    "20:30",
    "20:45",
    "21:00",
    "21:15",
    "21:30",
    "21:45",
    "22:00",
    "22:15",
    "22:30",
    "22:45",
    "23:00",
    "23:15",
    "23:30",
    "23:45",
    "24:00",
  ];
  const [pickUpTime, setPickUpTime] = useState([]);
  const [selectedCardValue, setSelectedCardValue] = useState(defaultCard || "");
  const [isCouponProcessing, setIsCouponProcessing] = useState(false);
  const [isLoadingAddons, setIsLoadingAddons] = useState(true);
  const [updateBtnFlag, setUpdateBtnFlag] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const initialHistoryStateRef = useRef(null);
  const preventBackRef = useRef(false);  
  const backHandledRef = useRef(false);
  const addedDropdownHistoryEntryRef = useRef(false);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

    if (!isLogin || cartItem.length === 0) {
      scrollToTop();
    }
    
    const handleStorageChange = () => {
      scrollToTop();
    };

    window.addEventListener('storage', handleStorageChange);
    
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [isLogin, cartItem.length]);

  useEffect(() => {  
    preventBackRef.current = dropdownOpen;
    
    if (!dropdownOpen) {
      backHandledRef.current = false;
    }

    if (dropdownOpen && !addedDropdownHistoryEntryRef.current) {
      window.history.pushState({ type: 'dropdown-open', timestamp: Date.now() }, '');
      addedDropdownHistoryEntryRef.current = true;
    }

    if (!dropdownOpen) {
      addedDropdownHistoryEntryRef.current = false;
    }
  }, [dropdownOpen]);

  useEffect(() => {  
    initialHistoryStateRef.current = window.history.state;
  }, []);

  useEffect(() => {  
    const handleBackButton = (e) => {    
      if (preventBackRef.current && !backHandledRef.current) {      
        backHandledRef.current = true;
        setDropdownOpen(false);
        e.preventDefault();
        e.stopPropagation(); 
        return true;
      }    
      return false;
    };  
    window.addEventListener('popstate', handleBackButton, { capture: true });
    
    return () => {
      window.removeEventListener('popstate', handleBackButton, { capture: true });
    };
  }, []);


  if (!window.dropdownBackHandler) {
    window.dropdownBackHandler = function(e) {
      const dropdownOpenElements = document.querySelectorAll('.MuiPopover-root');
      
      if (dropdownOpenElements.length > 0) {
        e.preventDefault();
        e.stopPropagation();
        
        document.dispatchEvent(new KeyboardEvent('keydown', { key: 'Escape' }));
        
        return true;
      }
      
      return false;
    };
    
    window.addEventListener('popstate', window.dropdownBackHandler, { capture: true });
  }

  useEffect(() => {
    setSelectedCardValue(defaultCard || "");
  }, [defaultCard]);

  useEffect(() => {
    const img = new Image();
    img.src = "/cupsImage.png";
  }, []);

  useEffect(() => {
    if (cartItem.length === 0) {
      dispatch(setTabValue(2));
    }
  }, [cartItem, dispatch]);

  useEffect(() => {
    if (cartItem.length === 0) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [cartItem]);

    useEffect(() => {
    if (storeName?.id) {
      getStoreStartendTime(storeName?.id)
        .then((res) => {
          let picupLater = handleStoreStartEndTimeSec(
            res,
            storeName?.time_zone
          );
          let timeZoneDate = new Date().toLocaleString("en-US", {
            timeZone: storeName?.time_zone,
          });
          let timeZoneDateHours =
            new Date(timeZoneDate)?.getHours() > 9
              ? new Date(timeZoneDate)?.getHours()
              : "0" + new Date(timeZoneDate)?.getHours();
          let timeZoneDateMinutes =
            new Date(timeZoneDate)?.getMinutes() > 9
              ? new Date(timeZoneDate)?.getMinutes()
              : "0" + new Date(timeZoneDate)?.getMinutes();
          let timeZoneDateMonth = new Date(timeZoneDate)?.getMonth();
          let timeZoneDateFullYear = new Date(timeZoneDate)?.getFullYear();
          let timeZoneDateDay = new Date(timeZoneDate)?.getDate();
          let timeZoneWeekDay = new Date(timeZoneDate)?.getDay();
          let minTime = timeZoneDateHours + ":" + timeZoneDateMinutes;
          let initialTime = picupLater?.status[timeZoneWeekDay]?.Start?.slice(
            0,
            5
          );
          let maxTime = picupLater?.status[timeZoneWeekDay]?.Close?.slice(0, 5);
          let range = timerange?.filter(
            (item) =>
              item > minTime &&
              item < maxTime &&
              new Date(
                timeZoneDateFullYear,
                timeZoneDateMonth,
                timeZoneDateDay,
                Number(maxTime?.slice(0, 2)),
                Number(maxTime?.slice(3))
              ) -
                new Date(
                  timeZoneDateFullYear,
                  timeZoneDateMonth,
                  timeZoneDateDay,
                  Number(item?.slice(0, 2)),
                  Number(item?.slice(3))
                ) >=
                900000 &&
              new Date(
                timeZoneDateFullYear,
                timeZoneDateMonth,
                timeZoneDateDay,
                Number(item?.slice(0, 2)),
                Number(item?.slice(3))
              ) -
                new Date(
                  timeZoneDateFullYear,
                  timeZoneDateMonth,
                  timeZoneDateDay,
                  timeZoneDateHours,
                  timeZoneDateMinutes
                ) >=
                900000 * 2 &&
              new Date(
                timeZoneDateFullYear,
                timeZoneDateMonth,
                timeZoneDateDay,
                Number(item?.slice(0, 2)),
                Number(item?.slice(3))
              ) -
                new Date(
                  timeZoneDateFullYear,
                  timeZoneDateMonth,
                  timeZoneDateDay,
                  Number(initialTime?.slice(0, 2)),
                  Number(initialTime?.slice(3))
                ) >=
                900000 * 3
          );

          let updatedRange = range?.map((time) => {
            return AmPmFormat(time);
          });
          setPickUpTime(updatedRange);
        })
        .catch((e) => {
          console.log(e);
          errorToastMessage();
        });
    }
  }, [remove]);

  const isGivexData = useSelector((state) => state.user.givexDetail);
  const newClasses = useStyle();
  const [showAd, setShowAd] = useState(false);

  const [showGuest, setshowGuest] = useState(false);
  const [showRewards, setshowRewards] = useState(false);
  const [pickupType, setPickupType] = useState("asap");
  const fiveStarRewardDetail = useSelector(
    (state) => state.user.fiveStarRewardDetail
  );
  const [promoCode, setPromoCode] = useState("");
  const [promoApply, setPromoApply] = useState();
  const [promoApplyError, setPromoApplyError] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [disc, setDisc] = useState(0);
  const [autoDisc, setAutoDisc] = useState(0);
  const [geenCheckBox, setGreenCheckbox] = useState(false);
  const [selectedGiftCard, setSelectedGiftCard] = useState(false);
  const discountDetail = useSelector((state) => state.user.discountDetail);
  const addOns = useSelector((state) => state.cart.addonItemList);
  const autoDiscountDetail = useSelector(
    (state) => state.user.autoDiscountDetail
  );
  const [showCombinePopUp, setShowCombinePopUp] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const apiCallsCompleted = useRef(false);

  useEffect(() => {
    dispatch(setTabValue(0));
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  }, []);

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        if (!storeName || !storeName.id) {
          return;
        }
        apiCallsCompleted.current = true;
        await Promise.all([
          getStoreStartendTime(storeName.id)
            .then((res) => {
              const picupLater = handleStoreStartEndTimeSec(res, storeName?.time_zone);
              let timeZoneDate = new Date().toLocaleString("en-US", {
                timeZone: storeName?.time_zone,
              });

              let timeZoneDateHours = new Date(timeZoneDate)?.getHours() > 9
                ? new Date(timeZoneDate)?.getHours()
                : "0" + new Date(timeZoneDate)?.getHours();

              let timeZoneDateMinutes = new Date(timeZoneDate)?.getMinutes() > 9
                ? new Date(timeZoneDate)?.getMinutes()
                : "0" + new Date(timeZoneDate)?.getMinutes();

              let timeZoneDateMonth = new Date(timeZoneDate)?.getMonth();
              let timeZoneDateFullYear = new Date(timeZoneDate)?.getFullYear();
              let timeZoneDateDay = new Date(timeZoneDate)?.getDate();
              let timeZoneWeekDay = new Date(timeZoneDate)?.getDay();
              let minTime = timeZoneDateHours + ":" + timeZoneDateMinutes;
              let initialTime = picupLater?.status[timeZoneWeekDay]?.Start?.slice(0, 5);
              let maxTime = picupLater?.status[timeZoneWeekDay]?.Close?.slice(0, 5);

              const range = timerange?.filter(
                (item) =>
                  item > minTime &&
                  item <= maxTime &&
                  new Date(
                    timeZoneDateFullYear,
                    timeZoneDateMonth,
                    timeZoneDateDay,
                    Number(maxTime?.slice(0, 2)),
                    Number(maxTime?.slice(3))
                  ) -
                  new Date(
                    timeZoneDateFullYear,
                    timeZoneDateMonth,
                    timeZoneDateDay,
                    Number(item?.slice(0, 2)),
                    Number(item?.slice(3))
                  ) >=
                    2400000 &&
                  new Date(
                    timeZoneDateFullYear,
                    timeZoneDateMonth,
                    timeZoneDateDay,
                    Number(item?.slice(0, 2)),
                    Number(item?.slice(3))
                  ) -
                  new Date(
                    timeZoneDateFullYear,
                    timeZoneDateMonth,
                    timeZoneDateDay,
                    timeZoneDateHours,
                    timeZoneDateMinutes
                  ) >=
                  900000 * 2 &&
                  new Date(
                    timeZoneDateFullYear,
                    timeZoneDateMonth,
                    timeZoneDateDay,
                    Number(item?.slice(0, 2)),
                    Number(item?.slice(3))
                  ) -
                  new Date(
                    timeZoneDateFullYear,
                    timeZoneDateMonth,
                    timeZoneDateDay,
                    Number(initialTime?.slice(0, 2)),
                    Number(initialTime?.slice(3))
                  ) >=
                  900000 * 3
              );

              let updatedRange = range?.map((time) => AmPmFormat(time));
              setPickUpTime(updatedRange);
          })
      ])
      } catch (error) {
        console.error("Error loading cart data:", error);
        errorToastMessage();
        apiCallsCompleted.current = false;
      }
    };
    loadInitialData();
  }, [dispatch, storeName.id, storeName?.time_zone]);


  useEffect(() => {
    return () => {
      apiCallsCompleted.current = false;
    };
  }, [storeName?.id]);

  useEffect(() => {
    if (cartItem.length === 0) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [cartItem]);

  let totalItem = cartItem?.map((elm) => {
    return elm?.quantity;
  });
  let totalCartItem = totalItem?.reduce((a, b) => a + b, 0);

  let AllItemPrice = 0;
  if (cartItem?.length) {
    AllItemPrice = cartItem
      .map((item) => item?.orderItemPrice * item?.quantity)
      .reduce((previousValue, currentValue) => previousValue + currentValue);
  }

  let taxValue =
    totalFivestar && (promoApply || alwaysPromoApply)
      ? Number(
        Number(
          (taxes *
            (AllItemPrice -
              (totalFivestar +
                Number(disc?.toFixed(2)) +
                Number(autoDisc.toFixed(2))) >
              0
              ? AllItemPrice -
              (totalFivestar +
                Number(disc?.toFixed(2)) +
                Number(autoDisc.toFixed(2)))
              : 0.0)) /
          100
        ).toFixed(2)
      )
      : promoApply || alwaysPromoApply
        ? Number(
          Number(
            (taxes *
              (AllItemPrice -
                (totalFivestar +
                  Number(disc.toFixed(2)) +
                  Number(autoDisc.toFixed(2))) >
                0
                ? AllItemPrice -
                (totalFivestar +
                  Number(disc.toFixed(2)) +
                  Number(autoDisc.toFixed(2)))
                : 0.0)) /
            100
          ).toFixed(2)
        )
        : promoApply || alwaysPromoApply
          ? Number(
            Number(
              (taxes *
                (AllItemPrice -
                  (Number(disc.toFixed(2)) + Number(autoDisc.toFixed(2))) >
                  0
                  ? AllItemPrice -
                  (Number(disc.toFixed(2)) + Number(autoDisc.toFixed(2)))
                  : 0.0)) /
              100
            ).toFixed(2)
          )
          : totalFivestar
            ? Number(
              Number(
                (taxes *
                  (AllItemPrice - (totalFivestar + Number(autoDisc.toFixed(2))) > 0
                    ? AllItemPrice - (totalFivestar + Number(autoDisc.toFixed(2)))
                    : 0.0)) /
                100
              ).toFixed(2)
            )
            : Number(Number((taxes * AllItemPrice) / 100).toFixed(2));

  let totalAmount =
    totalFivestar && (promoApply || alwaysPromoApply)
      ? Number(
        Number(
          (AllItemPrice -
            (totalFivestar +
              Number(disc.toFixed(2)) +
              Number(autoDisc.toFixed(2))) >
            0
            ? AllItemPrice -
            (totalFivestar +
              Number(disc.toFixed(2)) +
              Number(autoDisc.toFixed(2)))
            : 0.0) + taxValue
        ).toFixed(2)
      )
      : promoApply || alwaysPromoApply
        ? Number(
          Number(
            (AllItemPrice -
              (totalFivestar +
                Number(disc.toFixed(2)) +
                Number(autoDisc.toFixed(2))) >
              0
              ? AllItemPrice -
              (totalFivestar +
                Number(disc.toFixed(2)) +
                Number(autoDisc.toFixed(2)))
              : 0.0) + taxValue
          ).toFixed(2)
        )
        : promoApply || alwaysPromoApply
          ? Number(
            Number(
              (AllItemPrice -
                (Number(disc.toFixed(2)) + Number(autoDisc.toFixed(2))) >
                0
                ? AllItemPrice -
                (Number(disc.toFixed(2)) + Number(autoDisc.toFixed(2)))
                : 0.0) + taxValue
            ).toFixed(2)
          )
          : totalFivestar
            ? Number(
              Number(
                (AllItemPrice - (totalFivestar + Number(autoDisc.toFixed(2))) > 0
                  ? AllItemPrice - (totalFivestar + Number(autoDisc.toFixed(2)))
                  : 0.0) + taxValue
              ).toFixed(2)
            )
            : Number(Number(AllItemPrice + taxValue).toFixed(2));
  const discountTotal = useMemo(() => {
    if (totalFivestar && promoApply) {
      return Number(
        totalFivestar +
        (Number(autoDisc?.toFixed(2)) + Number(disc?.toFixed(2)))
      ).toFixed(2);
    } else if (promoApply) {
      return Number(autoDisc?.toFixed(2)) + Number(disc?.toFixed(2));
    } else if (totalFivestar) {
      return Number(totalFivestar) + Number(autoDisc.toFixed(2));
    }
    return 0.0 + Number(autoDisc).toFixed(2);
  }, [totalFivestar, promoApply, autoDisc, disc]);

  const handlePlaceOrder = () => {
    let pickupDate;
    let formattedPickUpLaterDate;
    let weekDayArray = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    let weekDay;
    if (pickupType === "asap") {
      dispatch(setPickUpLaterTime(""));
      pickupDate = new Date().toISOString();
    }
    if (pickupType === "later") {
      dispatch(setPickUpLaterTime(time));
      let y = time.slice(time.length - 2, time.length);
      let newdate = new Date(
        new Date().toLocaleString("en-US", {
          timeZone: storeName?.time_zone,
        })
      );
      let second =
        new Date(newdate)?.getSeconds().toString().length > 1
          ? new Date(newdate)?.getSeconds()
          : "0" + new Date(newdate)?.getSeconds();
      let day = new Date(newdate)?.getDay();
      weekDay = weekDayArray[day];
      let formattedDate;
      let modifiedTime = time;
      if (y === "AM") {
        if (time?.length !== 8) {
          modifiedTime = "0" + time;
        }
        formattedDate = `${modifiedTime.slice(0, 2)}:${modifiedTime.slice(
          modifiedTime.length - 5,
          modifiedTime.length - 3
        )}:${second}:000Z`;
        newdate.setHours(Number(modifiedTime.slice(0, 2)));
        newdate.setMinutes(
          Number(
            modifiedTime.slice(modifiedTime.length - 5, modifiedTime.length - 2)
          )
        );
        pickupDate = newdate.toISOString();
        formattedPickUpLaterDate = formattedDate;
      } else {
        let Time;
        if (time?.length !== 8) {
          modifiedTime = "0" + time;
        }
        Time =
          Number(modifiedTime.slice(0, 2)) + 12 === 24
            ? 12
            : Number(modifiedTime.slice(0, 2)) + 12;
        newdate.setHours(Time);
        newdate.setMinutes(
          modifiedTime.slice(modifiedTime.length - 5, modifiedTime.length - 2)
        );
        formattedDate = `${Time}:${modifiedTime.slice(
          modifiedTime.length - 5,
          modifiedTime.length - 3
        )}:${second}:000Z`;
        pickupDate = newdate.toISOString();
        formattedPickUpLaterDate = formattedDate;
      }
    }
    let pickupCartData = {
      subTotal: AllItemPrice,
      taxRate: taxes,
      webFee: webFee,
      totalDiscount:
        totalFivestar && promoApply
          ? Number(
            totalFivestar +
            (Number(autoDisc?.toFixed(2)) + Number(disc?.toFixed(2)))
          ).toFixed(2)
          : promoApply
            ? Number(autoDisc?.toFixed(2)) + Number(disc?.toFixed(2))
            : totalFivestar
              ? Number(totalFivestar) + Number(autoDisc?.toFixed(2))
              : 0.0 + Number(autoDisc)?.toFixed(2),
      promoDiscount: promoApply
        ? Number(disc?.toFixed(2))?.toString()
        : Number(autoDisc?.toFixed(2))?.toString(),
      taxValue: taxValue,
      webFeeValue: 0,
      total: totalAmount,
      promoCode: promoCode ? promoCode : "",
      pickupType: pickupType ? pickupType : "",
      pickupDate: pickupDate ? pickupDate : "",
      formattedDate: formattedPickUpLaterDate,
      weekDay: weekDay,
    };
    let orderDetailArray = cartItem.map((e, index) => {
      let selectedMenuServingDetail = [
        {
          created_at: e?.selectedCupSize[0]?.created_at,
          price: e?.selectedCupSize[0]?.price?.toString(),
          serving_actual_id:
            e?.selectedCupSize[0]?.serving_actual_id?.toString(),
          serving_description: e?.selectedCupSize[0]?.serving_description,
          serving_icon: e?.selectedCupSize[0]?.serving_icon,
          serving_id: e?.selectedCupSize[0]?.serving_id?.toString(),
          serving_name: e?.selectedCupSize[0]?.serving_name,
          status: e?.selectedCupSize[0]?.status,
          sku_code:e?.selectedCupSize[0]?.sku_code,                  
          serving_alias_name: e?.selectedCupSize[0]?.serving_alias_name,          
          updated_at: e?.selectedCupSize[0]?.updated_at,
        },
      ];
      let options = e.selectedCupSize.map((element) => {
        let detail = {
          modifier_group_name: "Cup Size",
          option: [
            {
              id: element.serving_actual_id?.toString(),
              isSelected: false,
              modifier_id: "",
              modifier_name: element.serving_name,
              sku_code:element?.sku_code,
              modifier_prefix_overide_mode: "No",
              modifier_prefix_overideMode: "",
              modifier_prefix_price: 0,
              modifier_prefixname: "",
              modifier_prefix_name: 0,
              modifier_price: element.price?.toString(),
              prefix_name: "",
              price: element.price?.toString(),
              quantity: 1,
              selected_modifier_serving: {
                price: "0",
                serving_name: "",
              },
              serving_id: element.serving_actual_id?.toString(),
              serving_name: element.serving_name,
              serving_price: element.price?.toString(),
              sub_modifier_id: 0,
              title: element.serving_name,
              modifier_alias:element.serving_alias_name,
            },
          ],
          required: true,
          selection_time: "Single",
          title: "Cup Size",
        };
        return detail;
      });
      let obj = Object.assign({}, e);
      obj.selectedOptions = [...options, ...e.selectedOptions];
      e = obj;

      let detail = {
        apply_option: "-1",
        category_id: e?.categoryId?.toString(),
        category_name: e?.categoryName,
        costToDisplay: e?.orderItemPrice?.toString(),
        customLoyaltyId: "",
        custom_loyalty_id: "",
        customizationOpt: e.selectedOptions,
        discountId: "-1",
        discount_price: "0",
        discount_rate: "0",
        discount_title: "",
        discount_type: "0",
        fivestar_discount: fiveStarResponse
          ? fiveStarResponse?.order_details[0]?.orderDetails[index]
            ?.fivestar_discount
          : "",
        fivestar_discount_id: fiveStarResponse
          ? fiveStarResponse?.order_details[0]?.orderDetails[index]
            ?.fivestar_discount_id
          : "",
        gift_card_no: "",
        gift_card_type: "",
        id: 1372,
        is_giftcard: "",
        itemAliasName: e?.itemAliasName,
        itemCustomizedPrice: e?.orderItemPrice?.toString()
          ? e?.orderItemPrice?.toString()
          : "0",
        loyaltyDetails: "New Order",
        loyalty_id: "",
        loyalty_points: "0",
        loyalty_value: "0",
        menu_id: e.id?.toString(),
        menu_image: e?.image,
        menu_name: e?.title,
        sku_code: e?.sku_code,
        toppings_counter_limit:e?.toppings_counter_limit||0,
        originalCost: e?.orderItemPrice?.toString()
          ? e?.orderItemPrice?.toString()
          : "0",
        quantity: e.quantity,
        selected_menu_serving_detail: selectedMenuServingDetail,
        serving_id: e.selectedCupSize[0].serving_actual_id?.toString(),
        serving_name: e.selectedCupSize[0].serving_name,
        serving_price: e.selectedCupSize[0].price?.toString(),
      };
      return detail;
    });
    let data = {
      customer_address: "",
      customer_email: userDetail?.email ? userDetail?.email : "NA",
      customer_id: userDetail?.person_id
        ? userDetail?.person_id.toString() // No need for additional check here
        : userDetail?.customer_id?.toString() || "0",
      customer_mobile_no: userDetail?.phone_no
        ? userDetail?.phone_no
        : userDetail?.phone_number || "NA",
      customer_name: userDetail?.first_name
        ? `${userDetail?.first_name} ${userDetail?.last_name ? userDetail?.last_name : ""
        }`
        : userDetail?.username,
      employee_id: 1,
      employee_name: "",
      givex_code: "",
      givex_num: "",
      is_emv: 0,
      loyalty_point: userDetail?.loyalty_point ? userDetail?.loyalty_point : 0,
      loyalty_request: {
        checkin: "",
        phone: "",
        points: [],
        rewards: [],
      },
      loyalty_reward_json: [
        {
          reward_label: fiveStarRewardDetail ? fiveStarRewardDetail?.perk || fiveStarRewardDetail?.shortname : "",
          reward_points: fiveStarRewardDetail
            ? fiveStarRewardDetail?.point_cost
            : 0,
          reward_uid: fiveStarRewardDetail ? fiveStarRewardDetail?.uid : "",
        },
      ],
      loyalty_value: 0,
      order_details: [
        {
          discount: discountDetail
            ? {
              discount: discountDetail[0]?.disArr?.discountRate
                ? discountDetail[0]?.disArr?.discountRate
                : autoDiscountDetail[0]?.disArr?.discountRate
                  ? autoDiscountDetail[0]?.disArr?.discountRate
                  : 0,
              id: discountDetail[0]?.disArr?.id
                ? discountDetail[0]?.disArr?.id
                : autoDiscountDetail[0]?.disArr?.id
                  ? autoDiscountDetail[0]?.disArr?.id
                  : -1,
              discountTitle: discountDetail[0]?.disArr?.title
                ? discountDetail[0]?.disArr?.title
                : autoDiscountDetail[0]?.disArr?.title
                  ? autoDiscountDetail[0]?.disArr?.title
                  : " ",
              type: discountDetail[0]?.disArr?.discountType
                ? discountDetail[0]?.disArr?.discountType
                : autoDiscountDetail[0]?.disArr?.discountType
                  ? autoDiscountDetail[0]?.disArr?.discountType
                  : "",
            }
            : {
              discount: 0,
              id: -1,
              discountTitle: "",
              type: "",
            },
          discount_total_price: pickupCartData?.promoDiscount?.toString(),
          loyalty_point: 0,
          loyalty_value: 0,
          orderDetails: orderDetailArray,
          pickup_date: pickupCartData.pickupDate,
          pickup_type: pickupCartData.pickupType,
          store_id: storeName?.id?.toString(),
          subTotal: pickupCartData?.subTotal?.toString(),
          tax: pickupCartData?.taxRate?.toString(),
          taxValue: (pickupCartData?.taxValue).toString(),
          serviceFeeValue: pickupCartData?.webFeeValue?.toString(),
          timezone: -330,
          total: pickupCartData?.total,
          total_fivestar_discount: fiveStarResponse
            ? fiveStarResponse?.order_details[0]?.total_fivestar_discount
            : 0,
        },
      ],
      order_status: "pending",
      order_total_price: totalAmount,
      order_type: "website",
      payment_method: [
        {
          amount: "0",
          amt: "0",
          auth_code: "",
          calculationAmt: "",
          card_last_no: 0,
          givex_code: 0,
          givex_num: 0,
          givex_trans_num: "",
          method: totalAmount === 0 ? "zero_payment" : (selectedCardValue.length > 8 ? "gift_card" : "credit_card"),
          payType: totalAmount === 0 ? "zero_payment" : (selectedCardValue.length > 8 ? "gift_card" : "credit_card"),
          paymentMethod: totalAmount === 0 ? "zero_payment" : (selectedCardValue.length > 8 ? "gift_card" : "credit_card"),
          trans_num: 0,
        },
      ],
      store_current_time: new Date()?.toISOString(),
      store_id: storeName?.id?.toString(),
      store_name: storeName?.store_name,
      timezone: -330,
      user_id: userDetail?.person_id
        ? userDetail?.person_id?.toString()
        : userDetail?.customer_id?.toString(),
      formattedPickUpLaterDate: pickupCartData?.formattedDate,
      weekDay: pickupCartData?.weekDay,
    };

    PlaceOrder(data)
      .then((res) => {
        if (res.responseCode === 1) {
          dispatch(setPlacedOrder(res?.result));
          handleBothCheckOut();
        } else {
          setOpen(false);
          setChecoutButtonDisabled(false);
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
          });
        }
      })
      .catch((err) => {
        setOpen(false);
        setChecoutButtonDisabled(false); 
        errorToastMessage();
        console.log(err);
      });
  };
  useEffect(() => {
    let pickupDate;
    if (pickupType === "asap") {
      pickupDate = new Date().toISOString();
    }
    let data = {
      subTotal: AllItemPrice,
      taxRate: taxes,
      webFee: webFee,
      totalDiscount:
        totalFivestar && promoApply
          ? Number(
            totalFivestar +
            (Number(autoDisc?.toFixed(2)) + Number(disc?.toFixed(2)))
          ).toFixed(2)
          : promoApply
            ? Number(autoDisc?.toFixed(2)) + Number(disc?.toFixed(2))
            : totalFivestar
              ? Number(totalFivestar) + Number(autoDisc.toFixed(2))
              : 0.0 + Number(autoDisc).toFixed(2),
      taxValue: taxValue,
      webFeeValue: 0,

      total: totalAmount,
      promoCode: promoCode ? promoCode : "",
      pickupType: pickupType ? pickupType : "",
      pickupDate: pickupDate ? pickupDate : "",
    };
    dispatch(setCartData(data));
  }, [
    totalCartItem,
    AllItemPrice,
    pickupType,
    taxes,
    webFee,
    totalFivestar,
    promoApply,
    autoDisc,
    disc,
    taxValue,
    totalAmount,
    promoCode,
    dispatch,
  ]);

  const handleCreditCardPayment = (data) => {
    if (data.profileId) {
      cardPointePaymentByProfileId(data)
        .then((res) => {
          setshowCard(false);
          // setCreditPopup(false);
          if (res.responseCode == 0) {
            let paymentDetails = {
              amount: "0",
              amt: "0",
              auth_code: "",
              calculationAmt: "",
              card_last_no: 0,
              givex_code: 0,
              givex_num: 0,
              givex_trans_num: "",
              method: res?.response?.paymentMethod
                ? res?.response?.paymentMethod
                : "credit_card",
              payId: res?.response?.savedCardId
                ? res?.response?.savedCardId
                : 0,
              payType: res?.response?.paymentMethod
                ? res?.response?.paymentMethod
                : "credit_card",
              paymentMethod: res?.response?.paymentMethod
                ? res?.response?.paymentMethod
                : "credit_card",
              trans_num: res?.response?.paymentRefId,
            };
            let checkoutPaymentMethodArray = [];
            checkoutPaymentMethodArray.push(paymentDetails);

            let cardRefund = {
              type: "credit_card",
              requestId: data?.requestId,
              transactionRefNumber: res?.response?.paymentRefId,
              storeId: storeName?.id,
            };
            dispatch(setGivexPaymentDetail(cardRefund));
            dispatch(setPaymentData(checkoutPaymentMethodArray));

            let path = `/payment/success?deviceType=web&paymentMethod1=${res?.response?.paymentMethod
                ? res?.response?.paymentMethod
                : "credit_card"
              }&payId1=${res?.response?.savedCardId ? res?.response?.savedCardId : 0
              }`;

            dispatch(setCheckoutFlag(true));
            dispatch(setIsCheckout(true));
            setOpen(false);
            navigate(path);
          } else if (
            res.responseCode == 1 &&
            res.responseMessage == "Duplicate Request Id"
          ) {
            setOpen(false);
            setChecoutButtonDisabled(false);
            toast.error("Server error ,Please try again", {
              position: toast.POSITION.TOP_RIGHT,
              pauseOnFocusLoss: false,
              autoClose: 1500,
              pauseOnHover: false,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
            });
            window.location.reload();
          } else if (
            res.responseCode == 1 &&
            res.responseMessage === "Invalid card"
          ) {
            setOpen(false);
            setChecoutButtonDisabled(false);
            toast.error("Please check your Card Number", {
              position: toast.POSITION.TOP_RIGHT,
              pauseOnFocusLoss: false,
              autoClose: 1500,
              pauseOnHover: false,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
            });
          } else if (
            res.responseCode == 1 &&
            res.responseMessage === "The Store does not accept this card"
          ) {
            setOpen(false);
            toast.error("oops something went wrong please try again", {
              position: toast.POSITION.TOP_RIGHT,
              pauseOnFocusLoss: false,
              autoClose: 1500,
              pauseOnHover: false,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
            });
            let path = `/payment/failure?deviceType=web`;
            navigate(path);
          } else if (
            res.responseCode == 1 &&
            res.responseMessage === "Expired card"
          ) {
            setOpen(false);
            toast.error("Your card has been expired", {
              position: toast.POSITION.TOP_RIGHT,
              pauseOnFocusLoss: false,
              autoClose: 1500,
              pauseOnHover: false,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
            });
            let path = `/payment/failure?deviceType=web`;
            navigate(path);
          } else if (
            res.responseCode == 1 &&
            res.responseMessage === "Service not allowed"
          ) {
            setOpen(false);
            toast.error("Your card Service not allowed", {
              position: toast.POSITION.TOP_RIGHT,
              pauseOnFocusLoss: false,
              autoClose: 1500,
              pauseOnHover: false,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
            });
            let path = `/payment/failure?deviceType=web`;
            navigate(path);
          } else {
            toast.error(res.responseMessage, {
              position: toast.POSITION.TOP_RIGHT,
              pauseOnFocusLoss: false,
              autoClose: 1500,
              pauseOnHover: false,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
            });
            setOpen(false);
            let path = `/payment/failure?deviceType=web`;
            navigate(path);
          }
        })
        .catch((err) => {
          setOpen(false);
          setChecoutButtonDisabled(false);
          console.log(err);
          if (err) {
            let path = `/payment/failure?deviceType=web`;
            navigate(path);
          }
        });
    } else {
      let cardData = {
        accountNumber: cardDataSave.account,
        amount: data.amount,
        cardExpiry: cardDataSave.cardExpiry,
        clientId: data.clientId,
        currency: data.currency,
        customerIdentifier: cardDataSave.customerName,
        customerName: cardDataSave.customerName,
        cvv: data.cvv,
        description: cardDataSave.customerName,
        deviceInfo: "",
        method: data.method,
        requestId: data.requestId,
        saveCard: 0,
        surcharge: 0,
        zipCode: data.zipCode,
        storeId: storeName?.id,       
      };
      cardPointePayment(cardData)
        .then((res) => {
          setshowCard(false);
          if (res.responseCode == 0) {
            let paymentDetails = {
              amount: "0",
              amt: "0",
              auth_code: "",
              calculationAmt: "",
              card_last_no: 0,
              givex_code: 0,
              givex_num: 0,
              givex_trans_num: "",
              method: res?.response?.paymentMethod
                ? res?.response?.paymentMethod
                : "credit_card",
              payId: res?.response?.savedCardId
                ? res?.response?.savedCardId
                : 0,
              payType: res?.response?.paymentMethod
                ? res?.response?.paymentMethod
                : "credit_card",
              paymentMethod: res?.response?.paymentMethod
                ? res?.response?.paymentMethod
                : "credit_card",
              trans_num: res?.response?.paymentRefId,
            };
            let checkoutPaymentMethodArray = [];
            checkoutPaymentMethodArray.push(paymentDetails);

            let cardRefund = {
              type: "credit_card",
              requestId: data?.requestId,
              transactionRefNumber: res?.response?.paymentRefId,
              storeId: storeName?.id,
            };
            dispatch(setGivexPaymentDetail(cardRefund));
            dispatch(setPaymentData(checkoutPaymentMethodArray));

            let path = `/payment/success?deviceType=web&paymentMethod1=${res?.response?.paymentMethod
                ? res?.response?.paymentMethod
                : "credit_card"
              }&payId1=${res?.response?.savedCardId ? res?.response?.savedCardId : 0
              }`;

            dispatch(setCheckoutFlag(true));
            dispatch(setIsCheckout(true));
            setOpen(false);
            navigate(path);
          } else if (
            res.responseCode == 1 &&
            res.responseMessage === "Duplicate Request Id"
          ) {
            setOpen(false);
            setChecoutButtonDisabled(false);
            toast.error("Server error ,Please try again", {
              position: toast.POSITION.TOP_RIGHT,
              pauseOnFocusLoss: false,
              autoClose: 1500,
              pauseOnHover: false,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
            });
            window.location.reload();
          } else if (
            res.responseCode == 1 &&
            res.responseMessage === "Invalid card"
          ) {
            setOpen(false);
            setChecoutButtonDisabled(false);
            toast.error("Please check your Card Number", {
              position: toast.POSITION.TOP_RIGHT,
              pauseOnFocusLoss: false,
              autoClose: 1500,
              pauseOnHover: false,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
            });
          } else if (
            res.responseCode == 1 &&
            res.responseMessage == "The Store does not accept this card"
          ) {
            setOpen(false);
            toast.error("oops something went wrong please try again", {
              position: toast.POSITION.TOP_RIGHT,
              pauseOnFocusLoss: false,
              autoClose: 1500,
              pauseOnHover: false,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
            });
            let path = `/payment/failure?deviceType=web`;
            navigate(path);
          } else if (
            res.responseCode == 1 &&
            res.responseMessage === "Expired card"
          ) {
            setOpen(false);
            toast.error("Your card has been expired", {
              position: toast.POSITION.TOP_RIGHT,
              pauseOnFocusLoss: false,
              autoClose: 1500,
              pauseOnHover: false,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
            });
            let path = `/payment/failure?deviceType=web`;
            navigate(path);
          } else if (
            res.responseCode == 1 &&
            res.responseMessage === "Service not allowed"
          ) {
            setOpen(false);
            toast.error("Your card Service not allowed", {
              position: toast.POSITION.TOP_RIGHT,
              pauseOnFocusLoss: false,
              autoClose: 1500,
              pauseOnHover: false,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
            });
            let path = `/payment/failure?deviceType=web`;
            navigate(path);
          } else {
            setOpen(false);
            let path = `/payment/failure?deviceType=web`;
            navigate(path);
          }
        })
        .catch((err) => {
          setOpen(false);
          setChecoutButtonDisabled(false);
          console.log(err);
          if (err) {
            let path = `/payment/failure?deviceType=web`;
            navigate(path);
          }
        });
    }
  };

  const handleGivexPayment = (givexData) => {
    setOpen(true);
    givexCardPayment(givexData)
      .then((res) => {
        if (
          res.responseCode === 0 &&
          res.responseMessage === "Payment Success"
        ) {
          let paymentDetails = {
            amount: "0",
            amt: "0",
            auth_code: "",
            calculationAmt: "",
            card_last_no: 0,
            givex_code: res?.response?.givex_code,
            givex_num: givexData?.cardNumber,
            givex_trans_num: "",
            method: "gift_card",
            payId: res?.response?.savedId,
            payType: res?.response?.paymentMethod,
            paymentMethod: "gift_card",
            trans_num: 0,
          };
          let checkoutPaymentMethodArray = [];
          checkoutPaymentMethodArray.push(paymentDetails);
          let givexDetailForRefund = {
            type: "givex",
            cardNumber: giftCardPaymentDetail.cardNumber,
            clientId: "AIPRUS",
            description: "demo payment",
            originalRequestId: givexData?.requestId,
            requestId: givexData?.requestId,
            transactionRefNumber: res?.response?.givex_code,
            storeId: storeName?.id,
          };
          dispatch(setGivexPaymentDetail(givexDetailForRefund));
          dispatch(setPaymentData(checkoutPaymentMethodArray));
          dispatch(setCheckoutFlag(true));
          dispatch(setIsCheckout(true));
          setOpen(false);
          let path = `/payment/success?deviceType=web&paymentMethod2=${res?.response?.paymentMethod}&payId2=${res?.response?.savedId}`;
          navigate(path);
        } else if (
          res.responseCode === 1 &&
          res.responseMessage === "Duplicate Request Id"
        ) {
          setOpen(false);
          setChecoutButtonDisabled(false);
          toast.error("please refresh your page", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
          });
        } else if (
          res.responseCode === 1 &&
          res.responseMessage === "Cert not exist"
        ) {
          setOpen(false);
          setChecoutButtonDisabled(false);
          toast.error("Please check your card number again", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
          });
        } else if (
          res.responseCode === 1 &&
          res.responseMessage === "Invalid user ID/pswd"
        ) {
          setOpen(false);
          setChecoutButtonDisabled(false);
          toast.error("oops something went wrong please try again", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
          });
        } else if (
          res.responseCode === 1 &&
          res.responseMessage === "Duplicate Request Id"
        ) {
          setOpen(false);
          setChecoutButtonDisabled(false);
          toast.error("oops something went wrong please try again", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
          });
          window.location.reload();
        } else {
          setOpen(false);
          setChecoutButtonDisabled(false);
          let path = `/payment/failure?deviceType=web`;
          navigate(path);
        }
      })
      .catch((err) => {
        setOpen(false);
        setChecoutButtonDisabled(false);
        console.log(err);
        errorToastMessage();
      });
  };

  const handleChangeCard = (event) => {
    if (!event.target.value) return;

    const cardValue = event.target.value;
    setSelectedCardValue(cardValue);
    setGreenCheckbox(true);

    const newDate = new Date();
    const requestId = `${newDate.toISOString()}${Math.floor(
      Math.random() * 10000 + 1
    )}`.replace(/[T.Z:_-]/g, "");

    if (cardValue.length > 8 || cardValue === "giftcard") {
      setSelectedGiftCard(true);
      setShowText(false);
      setGiftCardSelect(true);
      dispatch(setDefaultCardd());
      setGIFTCard("giftcard");
      setGiftCardAmount(totalAmount);
      setGiftCardPaymentDetail({
        cardNumber: cardValue,
        payment: totalAmount,
        requestId: requestId,
      });
      setGreenCheckbox(true);

      const givexData = {
        amount: 0,
        cardNumber: cardValue,
        clientId: "AIPRUS",
        customerIdentifier:
          user?.phone_number || user?.email || "Guest@gmail.com",
        customerName: user?.first_name
          ? `${user?.first_name} ${user?.last_name}`
          : "Guest",
        description: "demo payment",
        requestId: requestId,
        storeId: storeName?.id || 0,
        surcharge: 0,
      };

      getBalance(givexData)
        .then((ress) => {
          if (
            ress.responseCode === 0 &&
            ress.responseMessage === "Get Balance Successfully"
          ) {
            setAvailableBalance(ress?.response?.balance);
          }
        })
        .catch((error) => {
          console.error("Error checking balance:", error);
        });

      return;
    }

    if (cardValue !== "giftcard" && cardValue.length === 4) {
      setShowText(false);
      setSelectedGiftCard(false);

      const card = savedCards.find((e) => e.lastFourCardDigit === cardValue);

      if (card) {
        makeDefaultCard(card.customerId, card.cardType, card.multiUseToken)
          .then(() => {
            dispatch(setDefaultCardd(cardValue));
          })
          .catch((error) => {
            console.error("Error setting default card:", error);
          });
      }
    }
  };

  const routeChange = () => {
    let pickupDate;
    let formattedPickUpLaterDate;
    let weekDayArray = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    let weekDay;
    if (pickupType === "asap") {
      dispatch(setPickUpLaterTime(""));
      pickupDate = new Date().toISOString();
    }
    if (pickupType === "later") {
      dispatch(setPickUpLaterTime(time));
      let y = time.slice(time.length - 2, time.length);
      let newdate = new Date(
        new Date().toLocaleString("en-US", {
          timeZone: storeName?.time_zone,
        })
      );
      let second =
        new Date(newdate)?.getSeconds().toString().length > 1
          ? new Date(newdate)?.getSeconds()
          : "0" + new Date(newdate)?.getSeconds();
      let day = new Date(newdate)?.getDay();
      weekDay = weekDayArray[day];
      let formattedDate;
      let modifiedTime = time;
      if (y === "AM") {
        if (time?.length != 8) {
          modifiedTime = "0" + time;
        }
        formattedDate = `${modifiedTime.slice(0, 2)}:${modifiedTime.slice(
          modifiedTime.length - 5,
          modifiedTime.length - 3
        )}:${second}:000Z`;
        newdate.setHours(Number(modifiedTime.slice(0, 2)));
        newdate.setMinutes(
          Number(
            modifiedTime.slice(modifiedTime.length - 5, modifiedTime.length - 2)
          )
        );
        pickupDate = newdate.toISOString();
        formattedPickUpLaterDate = formattedDate;
      } else {
        let Time;
        if (time?.length != 8) {
          modifiedTime = "0" + time;
        }
        Time =
          Number(modifiedTime.slice(0, 2)) + 12 == 24
            ? 12
            : Number(modifiedTime.slice(0, 2)) + 12;
        newdate.setHours(Time);
        newdate.setMinutes(
          modifiedTime.slice(modifiedTime.length - 5, modifiedTime.length - 2)
        );
        formattedDate = `${Time}:${modifiedTime.slice(
          modifiedTime.length - 5,
          modifiedTime.length - 3
        )}:${second}:000Z`;
        pickupDate = newdate.toISOString();
        formattedPickUpLaterDate = formattedDate;
      }
    }
    let data = {
      subTotal: AllItemPrice,
      taxRate: taxes,
      webFee: webFee,
      totalDiscount:
        totalFivestar && promoApply
          ? Number(
            totalFivestar +
            (Number(autoDisc?.toFixed(2)) + Number(disc?.toFixed(2)))
          ).toFixed(2)
          : promoApply
            ? Number(autoDisc?.toFixed(2)) + Number(disc?.toFixed(2))
            : totalFivestar
              ? Number(totalFivestar) + Number(autoDisc.toFixed(2))
              : 0.0 + Number(autoDisc).toFixed(2),
      promoDiscount: promoApply
        ? Number(disc.toFixed(2)).toString()
        : Number(autoDisc.toFixed(2)).toString(),
      taxValue: taxValue,
      webFeeValue: 0,
      total: totalAmount,
      promoCode: promoCode ? promoCode : "",
      pickupType: pickupType ? pickupType : "",
      pickupDate: pickupDate ? pickupDate : "",
      formattedDate: formattedPickUpLaterDate,
      weekDay: weekDay,
    };
    let paymentDetails = [
      {
        amount: "0",
        amt: "0",
        auth_code: "",
        calculationAmt: "",
        card_last_no: 0,
        givex_code: 0,
        givex_num: 0,
        givex_trans_num: "",
        method: "Loyalty",
        payId: -1,
        payType: "zero_payment",
        paymentMethod: "zero_payment",
        trans_num: 0,
      },
    ];
    if (data.total == 0) {
      dispatch(setOrderTotalPrice(0));
      data.taxValue = 0;
      data.webFeeValue = 0;
      dispatch(setPaymentData(paymentDetails));
      dispatch(setCartData(data));
      dispatch(setCheckoutFlag(true));
      dispatch(setIsCheckout(true));
      let path = "/checkout";
      navigate({
        pathname: path,
      });
    } else if (GIFTCard && giftCardAmount != 0 && selectedGiftCard == true) {
      dispatch(setCartData(data));
      let newDate = new Date();
      let amount = Number(totalAmount).toFixed(2);
      let givexData = {
        amount: amount ? amount : Number(isGivexData?.payment),
        cardNumber: giftCardPaymentDetail.cardNumber,
        clientId: "AIPRUS",
        customerIdentifier: userDetail?.phone_number
          ? userDetail?.phone_number
          : userDetail?.phone_no
            ? userDetail?.phone_no
            : "Guest@gmail.com",
        customerName: userDetail?.first_name
          ? userDetail?.first_name + " " + userDetail?.last_name
          : "Guest",
        description: "demo payment",
        requestId: (
          newDate.toISOString() + Math.floor(Math.random() * 10000 + 1)
        )
          ?.toString()
          ?.replace(/[T.Z:_-]/g, ""),
        storeId: storeName?.id ? storeName?.id : 0,
        surcharge: 0,
      };
      handleGivexPayment(givexData);
      dispatch(setOrderTotalPrice(Number(totalAmount).toFixed(2)));
    } else if (defaultCard && selectedGiftCard != true) {
      dispatch(setCartData(data));
      let newDate = new Date();
      let amount = Number(totalAmount).toFixed(2);
      let creditPayment = {
        profileId: cardDataSave?.multiUseToken
          ? cardDataSave?.multiUseToken
          : cardDataSave?.profileId,
        amount: amount,
        clientId: "AIPRUS",
        currency: "USD",
        customerIdentifier: userDetail?.phone_number
          ? userDetail?.phone_number
          : userDetail?.phone_no
            ? userDetail?.phone_no
            : "Guest@gmail.com",
        customerName: cardDataSave?.customerName
          ? cardDataSave?.customerName
          : "Guest",
        description:
          userDetail?.first_name + " " + userDetail?.last_name
            ? userDetail?.last_name
            : "",
        method: "card",
        requestId: (
          newDate.toISOString() + Math.floor(Math.random() * 10000 + 1)
        )
          ?.toString()
          ?.replace(/[T.Z:_-]/g, ""),
        surcharge: 0,
        storeId: storeName?.id ? storeName?.id : 1,
        zipCode: cardDataSave?.zip,
        cvv: cardDataSave?.cvv ? cardDataSave?.cvv : cardDataSave?.cvv,
      };
      handleCreditCardPayment(creditPayment);
      dispatch(setOrderTotalPrice(Number(totalAmount).toFixed(2)));
    }
  };
  let orderDetailArray = cartItem.map((e, index) => {
    let selectedMenuServingDetail = [
      {
        created_at: e?.selectedCupSize[0]?.created_at,
        price: e?.selectedCupSize[0]?.price?.toString(),
        serving_actual_id: e?.selectedCupSize[0]?.serving_actual_id?.toString(),
        serving_description: e?.selectedCupSize[0]?.serving_description,
        serving_icon: e?.selectedCupSize[0]?.serving_icon,
        serving_id: e?.selectedCupSize[0]?.serving_id?.toString(),
        serving_name: e?.selectedCupSize[0]?.serving_name,
        status: e?.selectedCupSize[0]?.status,
        updated_at: e?.selectedCupSize[0]?.updated_at,
      },
    ];
    let options = e.selectedCupSize.map((element) => {
      let detail = {
        modifier_group_name: "Cup Size",
        option: [
          {
            id: element.serving_actual_id?.toString(),
            isSelected: false,
            modifier_id: "",
            modifier_name: element.serving_name,
            modifier_prefix_overide_mode: "No",
            modifier_prefix_overideMode: "",
            modifier_prefix_price: 0,
            modifier_prefixname: "",
            modifier_prefix_name: 0,
            modifier_price: element.price?.toString(),
            prefix_name: "",
            price: element.price?.toString(),
            quantity: 1,
            selected_modifier_serving: {
              price: "0",
              serving_name: "",
            },
            serving_id: element.serving_actual_id?.toString(),
            serving_name: element.serving_name,
            serving_price: element.price?.toString(),
            sub_modifier_id: 0,
            title: "",
          },
        ],
        required: true,
        selection_time: "Single",
        title: "Cup Size",
      };
      return detail;
    });
    let obj = Object.assign({}, e);
    obj.selectedOptions = [...options, ...e.selectedOptions];
    e = obj;

    let detail = {
      apply_option: "-1",
      category_id: e?.categoryId?.toString(),
      category_name: e?.categoryName,
      costToDisplay: e?.orderItemPrice?.toString(),
      customLoyaltyId: "",
      custom_loyalty_id: "",
      customizationOpt: e.selectedOptions,
      discountId: "-1",
      discount_price: "0",
      discount_rate: "0",
      discount_title: "",
      discount_type: "0",
      fivestar_discount: fiveStarResponse
        ? fiveStarResponse?.order_details[0]?.orderDetails[index]
          ?.fivestar_discount
        : "",
      fivestar_discount_id: fiveStarResponse
        ? fiveStarResponse?.order_details[0]?.orderDetails[index]
          ?.fivestar_discount_id
        : "",
      gift_card_no: "",
      gift_card_type: "",
      id: 1372,
      is_giftcard: "",
      itemAliasName: e?.itemAliasName,
      itemCustomizedPrice: e?.orderItemPrice?.toString()
        ? e?.orderItemPrice?.toString()
        : "0",
      loyaltyDetails: "New Order",
      loyalty_id: "",
      loyalty_points: "0",
      loyalty_value: "0",
      menu_id: e.id?.toString(),
      menu_image: e?.image,
      menu_name: e?.title,
      originalCost: e?.orderItemPrice?.toString()
        ? e?.orderItemPrice?.toString()
        : "0",
      quantity: e.quantity,
      selected_menu_serving_detail: selectedMenuServingDetail,
      serving_id: e.selectedCupSize[0].serving_actual_id?.toString(),
      serving_name: e.selectedCupSize[0].serving_name,
      serving_price: e.selectedCupSize[0].price?.toString(),
    };
    return detail;
  });
  let placeOrderRequest = {
    customer_address: "",
    customer_email: userDetail ? userDetail?.email : "",
    customer_id: userDetail?.person_id
      ? userDetail?.person_id?.toString()
      : userDetail?.customer_id?.toString(),
    customer_mobile_no: userDetail?.phone_no
      ? userDetail?.phone_no
      : userDetail?.phone_number,
    customer_name: userDetail?.first_name
      ? `${userDetail?.first_name} ${userDetail?.last_name ? userDetail?.last_name : ""
      }`
      : userDetail?.username,
    employee_id: 1,
    employee_name: "",
    givex_code: "",
    givex_num: "",
    is_emv: 0,
    loyalty_point: userDetail?.loyalty_point ? userDetail?.loyalty_point : 0,
    loyalty_request: {
      checkin: "",
      phone: "",
      points: [],
      rewards: [],
    },
    loyalty_reward_json: [
      {
        reward_label: fiveStarRewardDetail ? fiveStarRewardDetail?.perk : "",
        reward_points: fiveStarRewardDetail
          ? fiveStarRewardDetail?.point_cost
          : 0,
        reward_uid: fiveStarRewardDetail ? fiveStarRewardDetail?.uid : "",
      },
    ],
    loyalty_value: 0,
    order_details: [
      {
        discount: {
          id: -1,
          type: "",
          discount: 0,
        },
        discount_total_price: "0.0",
        loyalty_point: 0,
        loyalty_value: 0,
        orderDetails: orderDetailArray,
        pickup_date: cartData.pickupDate,
        pickup_type: cartData.pickupType,
        store_id: storeName?.id?.toString(),
        subTotal: AllItemPrice,
        tax: cartData?.taxRate?.toString(),
        taxValue: taxValue ? taxValue.toString() : "0",
        serviceFeeValue: cartData?.webFeeValue?.toString(),
        timezone: -330,
        total: cartData?.total,
        total_fivestar_discount: fiveStarResponse
          ? fiveStarResponse?.order_details[0]?.total_fivestar_discount
          : 0,
      },
    ],
    order_status: "pending",
    order_total_price: totalAmount,
    order_type: "website",
    payment_method: [],
    store_current_time: new Date()?.toISOString(),
    store_id: storeName?.id?.toString(),
    store_name: storeName?.store_name,
    timezone: -330,
    user_id: userDetail?.person_id
      ? userDetail?.person_id?.toString()
      : userDetail?.customer_id?.toString(),
    formattedPickUpLaterDate: cartData?.formattedDate,
    weekDay: cartData?.weekDay,
  };

  let data = {
    couponCode: promoCode,
    deviceType: 0,
    storeId: storeName?.id,
    userId: user?.person_id ? user?.person_id : user?.customer_id,
    placeOrderRequest: placeOrderRequest,
    fiveStarDiscount: fiveStarResponse
      ? fiveStarResponse?.order_details[0]?.total_fivestar_discount
      : 0,
  };
  let autoDiscountData = {
    deviceType: 0,
    storeId: storeName?.id,
    userId: user?.person_id ? user?.person_id : user?.customer_id,
    placeOrderRequest: placeOrderRequest,
    fiveStarDiscount: fiveStarResponse
      ? fiveStarResponse?.order_details[0]?.total_fivestar_discount
      : 0,
  };
  useEffect(() => {
    console.log(cartItem,
      fiveStarRewardDetail,Object.keys(fiveStarRewardDetail ? fiveStarRewardDetail : {}).length,"fiveStarRewardDetail")
    if (
      cartItem&&cartItem.length > 0 &&
      Object.keys(fiveStarRewardDetail ? fiveStarRewardDetail : {}).length === 0
    ) {
      ApplyAutomaticCoupon(autoDiscountData)
        .then((res) => {
          if (res?.message === "Promo Code applied successfully") {
            let discount = res?.discount ? res?.discount : 0;
            setAutoDisc(discount);
            dispatch(setAutoDiscountDetail(res?.result));
            setAlwaysPromoApply(true);
            if (res?.result[0]?.disArr?.combineDiscount) {
              setShowCombineDiscountPopup(true);
            } else {
              setShowCombineDiscountPopup(false);
            }
          } else {
            setAutoDisc(0);
            setAlwaysPromoApply(false);
          }
        })
        .catch((e) => {
          console.log(e, "error");
        });
    }
  }, [cartItem, fiveStarRewardDetail, isLogin, isGuestLogin]);

  const applyPromo = (fromPage) => {
    setButtonLoader(true);
    setIsCouponProcessing(true);

    // if (!isLogin && !isGuestLogin) {
    //   setPromoApplyError("Please login to apply promo code");
    //   setButtonLoader(false);
    //   setIsCouponProcessing(false);
    //   return;
    // }

    if (!promoCode || !promoCode.trim()) {
      setPromoApplyError("Please enter a promo code");
      setButtonLoader(false);
      setIsCouponProcessing(false);
      return;
    }
  
    if (localStorage.getItem("rewardProcessing")) {
      setPromoApplyError("Please wait while reward is being processed");
      setButtonLoader(false);
      setIsCouponProcessing(false);
      return;
    }
  
    if (alwaysPromoApply && fromPage) {
      setPromoApplyError("You cannot combine promotions");
      setButtonLoader(false);
      setIsCouponProcessing(false);
      return;
    }
  
    if (!promoApply && storeName?.id) {
      ApplyCoupon(data)
        .then((res) => {
          setIsCouponProcessing(false);
          if (res?.message === "All Fields are required") {
            setPromoApplyError("Promo Code Required");
            setShowCombineDiscountPopup(false);
            setButtonLoader(false);
            return;
          }
          if (res?.message !== "Promo Code applied successfully") {
            setPromoApplyError(res?.message);
            setShowCombineDiscountPopup(false);
            setButtonLoader(false);
            return;
          }
          setButtonLoader(false);
          setShowCombineDiscountPopup(false);
          if (autoDisc === 0) {
            dispatch(setDiscountDetail(res?.result));
            
            const canCombineDiscount = res?.result[0]?.disArr?.combineDiscount;
            setShowCombineDiscountPopup(canCombineDiscount);
  
            if (!canCombineDiscount) {
              if (Object.keys(fiveStarRewardDetail).length > 0) {
                dispatch(setFiveStarRewardDetail({}));
                dispatch(setFiveStarIndex(Number.MAX_VALUE));
              }
            }
  
            const discount = res?.discount || 0;
            if (discount > 0) {
              setPromoApply(res?.result[0]);
              setDisc(discount);
              setPromoApplyError("Promo Code applied successfully");
            }
            if (
              Object.keys(fiveStarRewardDetail).length > 0 &&
              fromPage !== "from AlertPopup" &&
              !canCombineDiscount
            ) {
              setPromoApplyError("Cannot combine these promotions in same order");
            }
          } else {
            setPromoApplyError("Cannot combine this Coupon");
          }
        })
        .catch((err) => {
          setIsCouponProcessing(false);
          setButtonLoader(false);
          errorToastMessage();
        });
    } else if (promoApply) {
      dispatch(setDiscountDetail(""));
      setPromoApply(null);
      setButtonLoader(false);
      setPromoApplyError("");
      setPromoCode("");
      setShowCombineDiscountPopup(false);
      dispatch(setFiveStarIndex(Number.MAX_VALUE));
      setIsCouponProcessing(false);
    } else {
      setPromoApply(null);
      setButtonLoader(false);
      dispatch(setDiscountDetail(""));
      setShowCombineDiscountPopup(false);
      setPromoCode("");
      setPromoApplyError("");
      setIsCouponProcessing(false);
    }
  };

  useEffect(() => {
    if (isLogin || isGuestLogin) {
      const savedPromoCode = localStorage.getItem('activePromoCode');
      const savedPromoError = localStorage.getItem('promoApplyError');
      if (savedPromoCode) {
        setPromoCode(savedPromoCode);
        setPromoApplyError(savedPromoError || "");
        applyPromo();
      }
    }
  }, [isLogin, isGuestLogin]);

  useEffect(() => {
    if (!isLogin && !isGuestLogin) {
      setPromoApplyError("");
      setPromoCode("");
      setPromoApply(false);
      dispatch(setDiscountDetail(""));
    }
  }, [isLogin, isGuestLogin, dispatch]);
  
  const handleBothCheckOut = () => {
    if (pickupType === "asap") {
      return routeChange();
    } else {
      if (time) {
        let timeZoneDate = new Date().toLocaleString("en-US", {
          timeZone: storeName?.time_zone,
        });
        let timeZoneDateHours =
          new Date(timeZoneDate)?.getHours() > 9
            ? new Date(timeZoneDate)?.getHours()
            : "0" + new Date(timeZoneDate)?.getHours();
        let timeZoneDateMinutes =
          new Date(timeZoneDate)?.getMinutes() > 9
            ? new Date(timeZoneDate)?.getMinutes()
            : "0" + new Date(timeZoneDate)?.getMinutes();
        let timeZoneDateMonth = new Date(timeZoneDate)?.getMonth();
        let timeZoneDateFullYear = new Date(timeZoneDate)?.getFullYear();
        let timeZoneDateDay = new Date(timeZoneDate)?.getDate();
        let modifiedTime = time;
        if (time?.length != 8) {
          modifiedTime = "0" + time;
        }
        let selectedPickUpLaterHour = TwentyFourHourFormat(modifiedTime);
        let splitHourAndMinute = selectedPickUpLaterHour?.split(":");
        let selectedSplitHour = splitHourAndMinute[0];
        let selectedSplitMinute = splitHourAndMinute[1];

        let differenceInOrderTimining =
          new Date(
            timeZoneDateFullYear,
            timeZoneDateMonth,
            timeZoneDateDay,
            Number(selectedSplitHour),
            Number(selectedSplitMinute)
          ) -
          new Date(
            timeZoneDateFullYear,
            timeZoneDateMonth,
            timeZoneDateDay,
            timeZoneDateHours,
            timeZoneDateMinutes
          );

        if (differenceInOrderTimining < 900000) {
          setRemove(true);
        } else {
          setShowAd(true);
          routeChange();
        }
      } else {
        toast.error("Please Choose PickUp Time", {
          position: toast.POSITION.TOP_RIGHT,
          pauseOnFocusLoss: false,
          autoClose: 1500,
          pauseOnHover: false,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
        });
      }
    }
  };

  const handleCheckOut = (e) => {
    if (pickupType === "later" && !time) {
      toast.error("Please select pickup time", {
        position: toast.POSITION.TOP_RIGHT,
        pauseOnFocusLoss: false,
        autoClose: 1500,
        pauseOnHover: false,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
      });
      return;
    }

    if (pickupType === "later" && time) {
      const currentTime = new Date();
      const [timeStr, period] = time.toLowerCase().split(' ');
      const [hours, minutes] = timeStr.split(':');
      const selectedTime = new Date();
      
      let hour24 = parseInt(hours);
      if (period === 'pm' && hour24 !== 12) {
        hour24 = hour24 + 12;
      } else if (period === 'am' && hour24 === 12) {
        hour24 = 0;
      }

      selectedTime.setHours(hour24);
      selectedTime.setMinutes(parseInt(minutes));
      selectedTime.setSeconds(0);

      const timeDifferenceInMinutes = Math.floor((selectedTime - currentTime) / (1000 * 60));
      
      if (timeDifferenceInMinutes < 15) {
        toast.error("The pickup time must be at least 15 minutes ahead of the current time.", {
          position: toast.POSITION.TOP_RIGHT,
          pauseOnFocusLoss: false,
          autoClose: 1500,
          pauseOnHover: false,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
        });
        return;
      }
    }

    if (!storeName || !storeName.id) {
      toast.error("Please select a store before proceeding to checkout", {
        position: toast.POSITION.TOP_RIGHT,
        pauseOnFocusLoss: false,
        autoClose: 1500,
        pauseOnHover: false,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
      });
      return;
    }

    if (!selectedCardValue) {
      toast.error("Please select a card before proceeding to checkout", {
        position: toast.POSITION.TOP_RIGHT,
        pauseOnFocusLoss: false,
        autoClose: 1500,
        pauseOnHover: false,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
      });
      return;
    }
    setOpen(true);
    setChecoutButtonDisabled(true);

    if (savedCards.length == 0) {
      setShowText(true);
    }
    if (availableBalance && availableBalance < giftCardAmount) {
      setOpen(false);
      setChecoutButtonDisabled(false); 
      toast.error("Available balance is low", {
        position: toast.POSITION.TOP_RIGHT,
        pauseOnFocusLoss: false,
        autoClose: 1500,
        pauseOnHover: false,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
      });
    } else {
      if (isGuestLogin) {
        setOpen(false);
        handlePlaceOrder(); 
      } else {
        getStoreStartendTime(storeName?.id).then((res) => {
          const storeStatus = handleStoreStartEndTimeSec(res, storeName?.time_zone);
          let timeRange = storeStatus?.timeRange;
          let storeActiveStatus = storeStatus?.storeActiveStatus
            ? storeStatus?.storeActiveStatus
            : "Active";
          let statusOnMobile = storeStatus?.statusOnMobile
            ? storeStatus?.statusOnMobile
            : "Active";
          if (
            storeStatus?.status[storeStatus?.timeZoneWeekDay].storeDayStatus ==
            false &&
            storeStatus?.status[storeStatus?.timeZoneWeekDay]?.onLineStatus ==
            "on" &&
            timeRange === true &&
            storeActiveStatus === "Active" &&
            statusOnMobile === "Active"
          ) {
            if (isGuestLogin) {
              setOpen(false);
              handlePlaceOrder();
            } else {
              checkUser(user?.phone_no ? user?.phone_no : user?.phone_number, 91)
                .then((res) => {
                  if (res.response_code === "1" && res.result.otp === false) {
                    handlePlaceOrder();
                  } else {
                    toast.error(
                      "This user is blocked. Please contact the support team."
                    );
                    setTimeout(() => {
                      localStorage.clear();
                      navigate("/");
                      window.location.reload();
                    }, 2000);
                  }
                })
                .catch((e) => {
                  toast.error("Oops something went wrong", {
                    position: toast.POSITION.TOP_RIGHT,
                    pauseOnFocusLoss: false,
                    autoClose: 1500,
                    pauseOnHover: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    draggable: true,
                  });
                });
            }
          } else {
            setOpen(false);
            setChecoutButtonDisabled(false);
            toast.warn("This store is closed. Please select another store", {
              position: toast.POSITION.TOP_RIGHT,
              pauseOnFocusLoss: false,
              autoClose: 1000,
              pauseOnHover: false,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
            });
          }
        });
      }
    }
  };

  useEffect(() => {
    setTotalFivestar(0);
    setfivestarDiscount(0);
  }, [cartItem]);

  useEffect(() => {
    setTotalFivestar(0);
    setfivestarDiscount(0);
    dispatch(setFiveStarIndex(Number.MAX_VALUE));
    dispatch(setFiveStarRewardDetail(""));
    dispatch(setFiveStarResponse(""));
    dispatch(setDiscountDetail(""));
    setDisc(0);
    setPromoApply();
    setPromoApplyError("");
    setPromoCode("");
  }, [cartItem?.length, totalCartItem]);

  const handleRemoveFiveStarLoyalty = () => {
    // setRemoveFiveStarRewards(true) ;
    dispatch(setRemoveReward(""));
    setTotalFivestar(0);
    setfivestarDiscount(0);
    dispatch(setFiveStarIndex(Number.MAX_VALUE));
    dispatch(setFiveStarRewardDetail(""));
    dispatch(setFiveStarResponse(""));
  };
  let personId = user?.person_id ? user?.person_id : user?.customer_id;
  useEffect(() => {
    if (user?.person_id || user?.customer_id) {
      if (isLogin && personId && storeName?.id) {
        getSavedCardDetail(
          user?.person_id ? user?.person_id : user?.customer_id
        )
          .then((res) => {
            if (res.responseCode === 1) {
              res.object.map((e) => {
                let imageUrl;
                switch (e.cardType.toLowerCase()) {
                  case "visa":
                    imageUrl = "./visa.png";
                    break;
                  case "mc":
                    imageUrl = "./mastercard.png";
                    break;
                  case "mastercard":
                    imageUrl = "./mastercard.png";
                    break;
                  case "amex":
                    imageUrl = "./Amex.png";
                    break;
                  case "american-express":
                    imageUrl = "./Amex.png";
                    break;
                  case "maestro":
                    imageUrl = "./maestro.png";
                    break;
                  case "dinersclub":
                    imageUrl = "./dinersclub.png";
                    break;
                  case "discover":
                    imageUrl = "./discover.png";
                    break;
                  case "jcb":
                    imageUrl = "./jcb.png";
                    break;
                  default:
                    imageUrl = "./creditcard.png";
                }
                return (e.imageUrl = imageUrl);
              });
              setSavedCard(res.object);
              let card = res.object.filter(
                (e) => e.lastFourCardDigit == defaultCard
              )[0];
              setCartDataSave(card);
              if (res.object.length > 0 && !defaultCard) {
                dispatch(
                  setDefaultCardd(
                    res.object.filter((e) => e.isDefault == true)[0]
                      ?.lastFourCardDigit
                      ? res.object.filter((e) => e.isDefault == true)[0]
                        .lastFourCardDigit
                      : res.object[0].lastFourCardDigit
                  )
                );
              }
            }
          })
          .catch((err) => {
            console.log(err);
            errorToastMessage();
          });
        getGivexDetails(storeName?.id)
          .then((res) => {
            setGivexCardExist(res?.givexExist);
            if (res?.givexExist === true) {
              getGivexCardDetail(
                user?.person_id ? user?.person_id : user?.customer_id
              ).then((res) => {
                res = res.map((object) => {
                  return { ...object, imageUrl: "./giftcard.jpg" };
                });

                if (savedGivxCards === false) {
                  setSavedCard((e) => Array.from(new Set([...e, ...res])));
                }
              });
            }
          })
          .catch((err) => {
            errorToastMessage();
          });
      }
      //showCard is Rmoved from dependency
    }
  }, [isLogin, personId, givexCardDetails, storeName?.id, defaultCard]);

  useEffect(() => {
    if (savedCards?.length == 1 && savedCards[0]?.givexCardNumber) {
      setGiftCardSelect(true);
    }
  }, [savedCards]);

  const handleSplitPaymentChange = (event) => {
    if (
      pickUpLaterTime &&
      !handlePlaceOrderWithTime(pickUpLaterTime, storeName?.time_zone)
    ) {
      setPickUpLaterPopup(true);
    } else {
      dispatch(setOrderTotalPrice(totalAmount));
      setGiftCard(true);
      setGiftCardSelect(true);
    }
  };
  useEffect(() => {
    dispatch(setGivexDetail(giftCardPaymentDetail));
  }, [giftCardPaymentDetail]);

  const handleSelectOpen = () => {
    backHandledRef.current = false;

    if (!addedDropdownHistoryEntryRef.current) {
      window.history.pushState({ type: 'dropdown-open', timestamp: Date.now() }, '');
      addedDropdownHistoryEntryRef.current = true;
    }
    
    setDropdownOpen(true);
    handleGreenCheckboxOnOpen();
  };
  
  const handleSelectClose = () => {
    setDropdownOpen(false);
    handleGreenCheckboxOnClose();
  };
  

  const handleGreenCheckboxOnOpen = () => {
    setGreenCheckbox(true);
  };
  const handleGreenCheckboxOnClose = () => {
    setGreenCheckbox(false);
  };

  const [open1, setOpen1] = useState(false); // State to control popup visibility

  const handleOpen = () => setOpen1(true); // Open popup
  const handleClose = () => setOpen1(false); // Close popup


  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    setTimeout(() => {
      setShowRightArrow(container.scrollWidth > container.clientWidth);
    }, 0);

    const handleScroll = () => {
      setShowLeftArrow(container.scrollLeft > 0);
      setShowRightArrow(
        Math.ceil(container.scrollLeft + container.clientWidth) < container.scrollWidth
      );
    };

    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [addOns]);

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  return (
    <>
      <div>
        <div className={classes.tabBarContainer}>
          <TabBar />
        </div>
        <div
          className={
            cartItem.length === 0 ? classes.emptyRoot : classes.filledRoot
          }
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%"
          }}
        >
          {cartItem.length === 0 ? (
            <>
              <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 'calc(100vh - 10px)' 
            }}>
                <Typography className={classes.emptyCartMessage}>
                  Your Cart is Empty!
                </Typography>
                <Button
                  className={classes.addItemsButton}
                  variant="contained"
                  onClick={() => {
                    dispatch(setTabValue(2));
                    navigate("/");
                  }}
                >
                  Add Items
                </Button>
              </div>
            </>
          ) : (
            <>
              <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={open}>
                <CircularProgress color="inherit" />
              </Backdrop>
              <Container sx={{ backgroundColor: { xs: "#FFFFFF", sm: "inherit" } }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "10px", marginBottom: "10px" }}>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <ArrowBackIosIcon
                    style={{ fill: "#C60734", cursor: "pointer" }}
                    onClick={() => {
                      dispatch(setTabValue(2));
                      let path = `/`;
                      navigate(path);
                    }}
                  />
                  <Typography
                    sx={{ color: "#C60734", cursor: "pointer", marginBottom: "-10px", marginTop: "-2px" }}
                    onClick={() => {
                      dispatch(setTabValue(2));
                      let path = `/`;
                      navigate(path);
                    }}
                    variant="h5"
                  >
                    Cart
                  </Typography>
                </Box>
                <Typography variant="h6" sx={{ display: {xs: "block", sm: "none"}, textAlign: { xs: "end", sm: "start" }, marginRight: "10px", }}>{`${totalCartItem} ${totalCartItem === 1 ? "Item" : "Items"}`}</Typography>
                </Box>
                {/* Main Cart Card */}
                <MyCard
                  sx={{
                    borderRadius: { xs: "", sm: "1rem" },
                    // marginTop: "1vw",
                    overflowY: "hidden",
                    width: { xs: "101vw", md: "100%" },
                    marginLeft: { xs: "-5vw", md: "0vw" },
                    marginBottom: { xs: "4rem", md: "0px" },
                  }}
                >
                  <Box
                    display="flex"
                    height={{ xs: "85%", md: "85%" }}
                    flexDirection={{ xs: "column", md: "row" }}
                  >
                    {/* Top Left Section: Cart Items */}
                    <Box
                      flex={1}
                      sx={{
                        flex: "1 1 60%",
                        minWidth: "200px",
                        display: "flex", // Enable flexbox
                        flexDirection: "column", // Stack children vertically
                        // height: "100%", // Ensure the parent container has a full height
                        justifyContent: "space-between", 
                        // marginBottom: { xs: 0, md: "inherit" },
                      }}
                    >
                        <Typography variant="h6" sx={{display: {xs: "none", sm: "block"}, textAlign: { xs: "end", sm: "start" }, margin: "10px" }}>{`${totalCartItem} ${totalCartItem === 1 ? "Item" : "Items"}`}</Typography>
                        <Box sx={{ padding: "1rem 0.23rem .1rem 1rem", height: {xs: "30vh", sm: "26vh", md: "22vw"}, overflowY: "auto",  maxHeight: "300px",}}>

                        {/* Individual Cart Items */}
                        <Box
                          sx={{
                            "&::-webkit-scrollbar": {
                              width: "6px",
                              position: "absolute",
                              right: 0
                            },
                            "&::-webkit-scrollbar-track": {
                              background: "#f1f1f1",
                              borderRadius: "10px"
                            },
                            "&::-webkit-scrollbar-thumb": {
                              borderRadius: "10px"
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                              background: "#555"
                            },
                            width: "100%",
                          }}
                        >
                          <Container
                            sx={{
                              paddingBottom: { xs: "0px", sm: "1rem" },
                               // maxHeight: {"45vh"},
                            }}
                          >
                            {cartItem.map((item, index) => {
                              return (
                                <>
                                  <PersonItem
                                    key={index}
                                    item={item}
                                    listKey={index}
                                    setDisc={setDisc}
                                    setPromoApply={setPromoApply}
                                    setPromoApplyError={setPromoApplyError}
                                    setPromoCode={setPromoCode}
                                    setTotalFivestar={setTotalFivestar}
                                    setUpdateBtnFlag={setUpdateBtnFlag}
                                  />
                                  {/* {index < cartItem.length - 1 && ( */}
                                    <Divider
                                      variant={"middle"}
                                      className={newClasses.divider}
                                    />
                                  {/* )} */}
                                </>
                              );
                            })}
                          </Container>
                        </Box>
                      </Box>

                      {/* Bottom Section: You may also like */}
                      {/* {addOns.length > 0 && ( */}
                        <Box
                          flex={1}
                          sx={{
                             // padding: "1rem",
                            overflowX: "auto",
                            // backgroundImage: `url("backgrounds.png")`,
                            backgroundColor: "#E7E7E7",
                            backgroundSize: "cover",
                            backgroundPosition: "center bottom",
                            backgroundRepeat: "no-repeat",
                            // height: "88vh",
                            // paddingBottom: "1rem",
                            // backgroundColor: "",
                            marginTop: "1.25rem",
                            // marginBottom: { md: "-10vh" }
                            // width:{ xs: "100%", md: "100%" },
                          }}
                        >
                          <Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                sx={{ ml: "1rem", mt: "1rem", mb: "-1rem" }}
                              >
                                <b>You may also like</b>
                              </Typography>

                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: 2,
                                  mr: "1rem",
                                  mb: "-2rem"
                                }}
                              >
                                {<IconButton
                                  onClick={scrollLeft}
                                  disabled={showLeftArrow ? false : true}
                                  sx={{
                                    backgroundColor: "#fff",
                                    borderRadius: "50%",
                                    boxShadow: 1,
                                    color: "#BA0C2F",
                                    "&:hover": {
                                      backgroundColor: "#f5f5f5",
                                    },
                                    width: 25, // Keeps IconButton size consistent
                                    height: 25,
                                  }}
                                >
                                  <ArrowRightAltIcon
                                    sx={{ transform: "rotate(180deg)" }}
                                  />
                                </IconButton>}

                                {<IconButton
                                  onClick={scrollRight}
                                  disabled={showRightArrow ? false : true}
                                  sx={{
                                    backgroundColor: "#fff",
                                    borderRadius: "50%",
                                    boxShadow: 1,
                                    color: "#BA0C2F",
                                    "&:hover": {
                                      backgroundColor: "#f5f5f5",
                                    },
                                    width: 25, // Keeps IconButton size consistent
                                    height: 25,
                                  }}
                                >
                                  <ArrowRightAltIcon />
                                </IconButton>}
                              </Box>
                            </Box>
                            <Box
                              ref={containerRef}
                              className="custom-scrollbar"
                              sx={{
                                padding: ".5rem",
                                display: "flex",
                                overflowX: "auto",
                                justifyContent: { xs: addOns.length <= 3 ? "center" : "flex-start", sm: addOns.length <= 6 ? "center" : "flex-start", md: addOns.length <= 4 ? "center" : "flex-start", },
                                minHeight: "11rem",
                                "&::-webkit-scrollbar": {
                                  display: "none"  
                                },
                                msOverflowStyle: "none",  
                                scrollbarWidth: "none",  
                                scrollBehavior: "smooth",
                                WebkitOverflowScrolling: "touch",
                              }}
                            >
                            {addOns.length > 0 ? (
                              addOns.map((e) => {
                                const isDisabled =
                                  !e.menu_serving_detail ||
                                  e.menu_serving_detail.length === 0;
                                return (
                                  <div
                                    key={e.id}
                                    style={{
                                      flexShrink: 0,
                                      marginRight: "0.5rem",
                                      marginBottom: "0.75rem",
                                    }}
                                  >
                                    <Tooltip
                                      title={
                                        isDisabled
                                          ? "Serving size is not available for this Item"
                                          : ""
                                      }
                                      disableFocusListener
                                      followCursor
                                    >
                                      <span>
                                        <Card
                                          key={e?.id}
                                          props={e}
                                          likeItem={true}
                                          isDisabled={isDisabled}
                                        />
                                      </span>
                                    </Tooltip>
                                  </div>
                                );
                              })
                            ) : (
                              <Typography sx={{ padding: "1rem" }}>
                                No additional items available for this store
                              </Typography>
                            )}
                            </Box>
                          </Box>
                        </Box>
                      {/* )} */}
                    </Box>

                    {/* Right Section: Summary  #FAE2E3*/}
                    <Box
                      flex={1}
                      sx={{
                        padding: "2%",
                        backgroundColor: "white",
                        minWidth: "200px",
                        marginTop: { xs: "", md: "-11px" }, // Proper syntax for responsive marginTop
                        flex: "1 1 40%",
                        order: { xs: 3, md: 2 },
                        boxShadow: "-4px 0px 10px rgba(0, 0, 0, 0.1)"
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: "10px",
                          ml: { xs: "1rem", md: "0", },
                          mr: { xs: ".5rem", md: "0" },
                        }}
                      >
                        <Box display="flex" flex={1}>
                          <TextField
                            value={promoCode}
                            onChange={(e) => {
                              setPromoCode(e.target.value);
                              if (!e.target.value) {
                                setPromoApplyError("");
                              }
                            }}
                            InputProps={{
                              disableUnderline: true, // Remove default underline
                              readOnly: promoApply && !alwaysPromoApply ? true : false,
                              sx: {
                                fontFamily: "Arial, sans-serif", // Custom font
                                color: "#757575", // Gray text
                                border: "2px solid #C60734", // Apply border to the input
                                padding: "0.5rem 1rem", // Add padding inside the input
                                borderRadius: "5px 0px 0px 5px", // Optional: Add border radius
                                height: "2.9rem",
                                backgroundColor: "white", 
                              },
                            }}
                            placeholder="Enter Promo Code Here"
                            variant="standard"
                            sx={{
                              flex: 1,
                              backgroundColor: "#e0e0e0", // Light gray background
                            }}
                          />
                        </Box>

                        <Button
                          style={{
                            backgroundColor: "white",
                            color: "#C60734",
                            padding: "0.5rem 1rem",
                            border: "2px solid #C60734",
                            borderLeft: "none",
                            borderRadius: "0 5px 5px 0", // Rounded corners on the right
                            textTransform: "none",
                            fontWeight: "bold",
                            fontSize: "1rem",
                            height: "2.9rem",
                          }}
                          variant="contained"
                          onClick={applyPromo}
                        >
                          {buttonLoader ? (
                            <ButtonLoader props={"4rem"} />
                          ) : promoApply ? (
                            "Remove"
                          ) : (
                            "Apply"
                          )}
                        </Button>
                      </Box>

                      <div
                        className={
                          promoApplyError == "Promo Code applied successfully"
                            ? newClasses.errorS
                            : newClasses.error
                        }
                        style={{ 
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '8px',
                          marginTop: '8px'
                        }}
                      >
                      {promoApplyError ? (
                          <>
                            <span>{promoApplyError}</span>
                            {promoApplyError === "Cannot combine these promotions in same order" && (
                              <CloseIcon 
                                sx={{ 
                                  fontSize: 18,
                                  color: "#C60734",
                                  cursor: "pointer",
                                  backgroundColor: "#FFE0E6",
                                  borderRadius: "50%",
                                  padding: "2px",
                                  transition: "all 0.2s ease",
                                  "&:hover": {
                                    backgroundColor: "#FFD0D8",
                                  }
                                }}
                                onClick={() => {
                                  setPromoApplyError("");
                                  setPromoCode("");
                                  dispatch(setDiscountDetail(""));
                                  setPromoApply(null);
                                  setDisc(0);
                                  localStorage.removeItem('activePromoCode');
                                  localStorage.removeItem('promoApplyError');
                                }}
                              />
                            )}
                          </>
                        ) : (
                          <br />
                        )}
                      </div>
                      {!isGuestLogin && <Divider />}

                      {isLogin && (
                        <>
                          <Box sx={{
                            ml: { xs: "1rem", md: "0", },
                            mr: { xs: ".5rem", md: "0", }
                          }}>
                            <Button
                              fullWidth
                              variant="contained"
                              onClick={handleOpen}
                              sx={{
                                textTransform: "none",
                                backgroundColor: "#C60734",
                                color: "#fff",
                                "&:hover": {
                                  backgroundColor: "#C60734",
                                },
                                // ml : { xs: "1rem", md: "0", },
                                // mr:{xs: ".5rem", md: "0",}
                              }}
                            >
                              Rewards
                            </Button>
                          </Box>
                          {(fiveStarRewardDetail?.perk ||
                            fiveStarRewardDetail?.shortname ||
                            promoApply) && (
                              <Box
                                sx={{
                                  mt: 1,
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 0.5,
                                }}
                              >
                                {fiveStarRewardDetail?.shortname && (
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: "#C60734",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      padding: "4px",
                                      position: "relative",
                                      "& span": {
                                        display: "inline-flex",
                                        alignItems: "center",
                                        gap: "8px",
                                        position: "relative",
                                        paddingRight: "24px"
                                      }
                                    }}
                                  >
                                     <span>
                                    <CheckCircleIcon
                                      sx={{ fontSize: 18, color: "#4CAF50" }}
                                    />
                                    {fiveStarRewardDetail.shortname}
                                    <CloseIcon 
                                        sx={{ 
                                          fontSize: 18, 
                                          color: "#C60734",
                                          cursor: "pointer",
                                          position: "absolute",
                                          right: 0,
                                          top: "50%",
                                          transform: "translateY(-50%)",
                                          backgroundColor: "#FFE0E6",
                                          borderRadius: "50%",
                                          padding: "2px",
                                          transition: "all 0.2s ease",
                                          "&:hover": {
                                            backgroundColor: "#FFD0D8",
                                          }
                                        }}
                                        onClick={handleRemoveFiveStarLoyalty}
                                      />
                                    </span>
                                  </Typography>
                                )}
                                {fiveStarRewardDetail?.perk && (
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: "#C60734",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      padding: "4px",
                                      position: "relative",
                                      "& span": {
                                        display: "inline-flex",
                                        alignItems: "center",
                                        gap: "8px",
                                        position: "relative",
                                        paddingRight: "24px"
                                      }
                                    }}
                                  >
                                    <span>
                                    <CheckCircleIcon
                                      sx={{ fontSize: 18, color: "#4CAF50" }}
                                    />
                                    {fiveStarRewardDetail.perk}
                                    <CloseIcon 
                                    sx={{ 
                                          fontSize: 18, 
                                      color: "#C60734",
                                          cursor: "pointer",
                                          position: "absolute",
                                          right: 0,
                                          top: "50%",
                                          transform: "translateY(-50%)",
                                          backgroundColor: "#FFE0E6",
                                          borderRadius: "50%",
                                          padding: "2px",
                                          transition: "all 0.2s ease",
                                          "&:hover": {
                                            backgroundColor: "#FFD0D8",
                                          }
                                        }}
                                        onClick={handleRemoveFiveStarLoyalty}
                                      />
                                    </span>
                                  </Typography>
                                )}
                                {promoApply && promoApply?.disArr?.title && (
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: "#C60734",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: 1,
                                      padding: "4px",
                                    }}
                                  >
                                    {/* <CheckCircleIcon
                                    sx={{ fontSize: 16, color: "#4CAF50" }}
                                  />
                                  {promoApply.disArr.title} */}
                                  </Typography>
                                )}
                              </Box>
                            )}
                        </>
                      )}

                      <Box mt={2}>
                        <div className={newClasses.billOrientation}>
                          <Typography
                            fontSize={14}
                            variant="subtitle1"
                            gutterBottom
                            sx={{
                              ml: { xs: "1rem", md: "0", },
                            }}
                          >
                            Sub-Total:
                          </Typography>
                          <Typography
                            fontSize={14}
                            variant="subtitle1"
                            gutterBottom
                            sx={{
                              mr: { xs: "1rem", md: "0", }
                            }}
                          >{`$${numberWithCommas(
                            Number(AllItemPrice).toFixed(2)
                          )}`}</Typography>
                        </div>
                        <div className={newClasses.billOrientation}>
                          <Typography
                            fontSize={14}
                            variant="subtitle1"
                            gutterBottom
                            sx={{
                              ml: { xs: "1rem", md: "0", },
                            }}
                          >
                            Discounts:
                          </Typography>
                          <Typography
                            fontSize={14}
                            variant="subtitle1"
                            gutterBottom
                            sx={{
                              mr: { xs: "1rem", md: "0", }
                            }}
                          >
                            {`-$${discountTotal > 0
                                ? discountTotal <= AllItemPrice
                                  ? Number(discountTotal).toFixed(2)
                                  : Number(AllItemPrice).toFixed(2)
                                : "0.00"
                              }`}
                          </Typography>
                        </div>

                        <div className={newClasses.billOrientation}>
                          <Typography
                            fontSize={14}
                            variant="subtitle1"
                            gutterBottom
                            sx={{
                              ml: { xs: "1rem", md: "0", }
                            }}
                          >
                            Tax:
                          </Typography>

                          <Typography
                            fontSize={14}
                            variant="subtitle1"
                            gutterBottom
                            sx={{
                              mr: { xs: "1rem", md: "0", }
                            }}
                          >
                            {`$${taxValue > 0
                                ? numberWithCommas(Number(taxValue).toFixed(2))
                                : "0.00"
                              }`}
                          </Typography>
                        </div>

                        <Divider sx={{ margin: "0px 0px 4px" }} />
                        <div className={newClasses.billOrientation}>
                          <Typography
                            fontSize={14}
                            variant="subtitle1"
                            gutterBottom
                            sx={{
                              ml: { xs: "1rem", md: "0", },
                            }}
                          >
                            Total:
                          </Typography>

                          <Typography
                            fontSize={14}
                            variant="subtitle1"
                            gutterBottom
                            sx={{
                              mr: { xs: "1rem", md: "0", }
                            }}
                          >
                            {`$${totalAmount > 0
                                ? numberWithCommas(
                                  Number(totalAmount).toFixed(2)
                                )
                                : "0.00"
                              }`}
                          </Typography>
                        </div>
                        <Divider />
                      </Box>
                      <Box display="flex" gap="1rem">
                        <Box
                          sx={{
                            display: {
                              lg: "flex",
                              md: "flex",
                              sm: "flex",
                              xs: "flex",
                            },
                            flexDirection: { xs: "row" },
                            alignItems: { xs: "end" },
                          }}
                        >
                          <Box sx={{
                            marginTop: "10px", ml: { xs: "1rem", md: "0", },
                            mr: { xs: ".5rem", md: "0", }
                          }}>
                            <FormControl>
                              <RadioGroup
                                sx={{ display: { xs: "none", sm: "block" } }}
                                defaultValue="asap"
                                row
                              >
                                <FormControlLabel
                                  value="asap"
                                  onChange={(e) => {
                                    setPickupType(e.target.value);
                                  }}
                                  control={<Radio />}
                                  label="Pickup Now"
                                  sx={{
                                    "& .MuiTypography-root": {
                                      color: "black",
                                      fontSize: ".8rem",
                                    },
                                    // ml : { xs: "1rem", md: "0", }   
                                  }}
                                />
                                {pickUpTime?.length > 0 && (
                                  <FormControlLabel
                                    value="later"
                                    onChange={(e) => {
                                      setPickupType(e.target.value);
                                    }}
                                    control={<Radio />}
                                    label="Pickup Later"
                                    sx={{
                                      "& .MuiTypography-root": {
                                        color: "black",
                                        fontSize: ".8rem",
                                      },
                                      // ml : { xs: "1rem", md: "0", }   
                                    }}
                                  />
                                )}
                              </RadioGroup>
                              <RadioGroup
                                sx={{ display: { xs: "flex", sm: "none" } }}
                                defaultValue="asap"
                              >
                                <FormControlLabel
                                  value="asap"
                                  onChange={(e) => {
                                    setPickupType(e.target.value);
                                  }}
                                  control={<Radio />}
                                  label="Pickup Now"
                                  sx={{
                                    "& .MuiTypography-root": {
                                      color: "black",
                                      fontSize: ".8rem",
                                    },
                                    // ml : { xs: "1rem", sm: "0", }   
                                  }}
                                />
                                {pickUpTime?.length > 0 && (
                                  <FormControlLabel
                                    value="later"
                                    onChange={(e) => {
                                      setPickupType(e.target.value);
                                    }}
                                    control={<Radio />}
                                    label="Pickup Later"
                                    sx={{
                                      "& .MuiTypography-root": {
                                        color: "black",
                                        fontSize: ".8rem",
                                      },
                                      // ml : { xs: "1rem", sm: "0", }   
                                    }}
                                  />
                                )}
                              </RadioGroup>
                            </FormControl>
                          </Box>
                        </Box>
                      </Box>
                      <div>
                        {pickupType === "later" && pickUpTime?.length > 0 ? (
                          <>
                            <Box sx={{
                              ml: { xs: "1rem", md: "0", },
                              mr: { xs: ".5rem", md: "0", }
                            }}>
                              <FormControl fullWidth sx={{ marginTop: ".8rem", }}>
                                <InputLabel>Time</InputLabel>
                                <Select
                                  MenuProps={MenuProps}
                                  value={
                                    time
                                      ? time
                                      : new Date().toTimeString().slice(0, 5)
                                  }
                                  variant="outlined"
                                  onChange={handleChange}
                                  label="Time"
                                  defaultOpen={true}
                                  size="small"
                                  sx={{
                                    minWidth: {
                                      lg: "8rem",
                                      md: "8rem",
                                      sm: "7rem",
                                      xs: "6rem",
                                    },
                                  }}
                                >
                                  <MenuItem value={""} disabled>
                                    {"Select time"}
                                  </MenuItem>
                                  {pickUpTime.map((item, index) => {
                                    return (
                                      <MenuItem value={item} key={index}>
                                        {item}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Box>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <Box className={newClasses.location} sx={{
                        ml: { xs: ".8rem", md: "0", },
                        mr: { xs: ".5rem", md: "0", }
                      }}>
                        <LocationOnIcon sx={{
                          color: "#C60734",
                          //  ml: { xs: "1.45rem", sm: "0" }
                        }} />
                        <Box variant="subtitle1"
                          sx={{
                            // ml: { xs: "2rem", sm: "0" }
                          }}
                        >
                          {`${storeName.address
                              ? storeName.address.replace("~", " ")
                              : ""
                            } ${storeName.zip_code}`}
                        </Box>
                      </Box>
                      <Box mt={1}>
                        {isLogin === false && isGuestLogin === false ? (
                          <>
                            <div className={newClasses.buttons}>
                              <Button
                                className={newClasses.btnLogin}
                                variant="contained"
                                onClick={() => {
                                  setShowAd(true);
                                }}
                              >
                                Log in and Checkout
                              </Button>
                            </div>
                            <div className={newClasses.guest}>
                              <div
                              onClick={() => {
                                setshowGuest(true);
                              }}
                              variant="subtitle1"
                              style={{ display: 'flex', alignItems: 'center' }} 
                              >
                                Continue as Guest <ArrowForwardIcon style={{ marginLeft: '0.5rem' }} /> 
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {savedCards.length > 0 &&
                             (defaultCard || GiftCardSelect) && (
                              <Box sx={{
                                ml: { xs: "1rem", md: "0", },
                                mr: { xs: ".5rem", md: "0", }
                              }}>
                                <FormControl fullWidth>
                                  <InputLabel style={{ marginTop: "0.3rem" }}>
                                    Select Card
                                  </InputLabel>
                                  <Select
                                    value={selectedCardValue}
                                    label="Select Card "
                                    style={{ marginTop: "0.3rem" }}
                                    onChange={handleChangeCard}
                                    open={dropdownOpen}
                                    onClose={handleSelectClose}
                                    onOpen={handleSelectOpen}
                                    renderValue={(selected) => (
                                        <div
                                          style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Box style={{ display: "flex" }}>
                                            <div style={{ width: "2.5rem" }}>
                                              <Box
                                                component="img"
                                                sx={{
                                                  width: "3rem",
                                                  marginTop: "0.2rem",
                                                }}
                                                src={
                                                  savedCards.find(
                                                    (card) =>
                                                      card.lastFourCardDigit ===
                                                      selected ||
                                                      card.givexCardNumber ===
                                                      selected
                                                  )?.imageUrl
                                                }
                                              />
                                            </div>
                                            <Typography
                                              variant="subtitle1"
                                              marginLeft="1.6rem"
                                              marginTop="0.3rem"
                                            >
                                              {` ****${selected?.substring(
                                                selected.length - 4
                                              )} `}
                                            </Typography>
                                          </Box>
                                        </div>
                                      )}
                                    >
                                      {savedCards.map((cardDetail, index) => (
                                        <MenuItem
                                          key={index}
                                          value={
                                            cardDetail.lastFourCardDigit ||
                                            cardDetail.givexCardNumber
                                          }
                                        >
                                          <div
                                            style={{
                                              width: "100%",
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Box style={{ display: "flex" }}>
                                              <div style={{ width: "2.5rem" }}>
                                                <Box
                                                  component="img"
                                                  sx={{
                                                    width: "3rem",
                                                    marginTop: "0.2rem",
                                                  }}
                                                  src={cardDetail.imageUrl}
                                                />
                                              </div>
                                              <Typography
                                                variant="subtitle1"
                                                marginLeft="1.6rem"
                                                marginTop="0.3rem"
                                              >
                                                {cardDetail.lastFourCardDigit
                                                  ? ` ****${cardDetail.lastFourCardDigit} `
                                                  : ` ****${cardDetail.givexCardNumber.substring(
                                                    17,
                                                    21
                                                  )} `}
                                              </Typography>
                                            </Box>
                                            {(cardDetail.lastFourCardDigit ===
                                              selectedCardValue ||
                                              cardDetail.givexCardNumber ===
                                              selectedCardValue) &&
                                              geenCheckBox && (
                                                <CheckCircleRoundedIcon
                                                  sx={{
                                                    color: "green",
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              )}
                                          </div>
                                        </MenuItem>
                                      ))}
                                      <MenuItem>
                                        <Box
                                          onClick={() => {
                                            setshowCard(true);
                                          }}
                                          sx={{
                                            display: "flex",
                                            marginBottom: "0.5rem",
                                            marginTop: "0.5rem",
                                            width: "100%",
                                          }}
                                        >
                                          <AddCircleOutlineIcon />
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                              width: "100%",
                                            }}
                                          >
                                            <Typography
                                              variant="subtitle1"
                                              marginLeft="0.5rem"
                                            >
                                              Credit or Debit card
                                            </Typography>
                                          </div>
                                        </Box>
                                      </MenuItem>
                                      {givexCardExist === true && (
                                        <MenuItem>
                                          <Box
                                            onClick={handleSplitPaymentChange}
                                            sx={{
                                              display: "flex",
                                              marginBottom: "0.5rem",
                                              marginTop: "0.5rem",
                                              width: "100%",
                                            }}
                                          >
                                            <AddCircleOutlineIcon />
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                width: "100%",
                                              }}
                                            >
                                              <Typography
                                                variant="subtitle1"
                                                marginLeft="0.5rem"
                                              >
                                                Gift card
                                              </Typography>
                                            </div>
                                          </Box>
                                        </MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </Box>
                              )}
                              {savedCards.length == 0 && (
                            <FormControl fullWidth>
                              <InputLabel style={{ marginTop: "0.3rem" }}>
                                Select Card
                              </InputLabel>
                              <Select
                                label="Select Card "
                                style={{ marginTop: "0.3rem" }}
                                onChange={handleChangeCard}
                                open={dropdownOpen}
                                onClose={handleSelectClose}
                                onOpen={handleSelectOpen}
                              >
                                  <MenuItem>
                                    <Box
                                      onClick={() => {
                                        setshowCard(true);
                                      }}
                                      sx={{
                                        display: "flex",
                                        marginBottom: "0.5rem",
                                        marginTop: "0.5rem",
                                        width: "100%",
                                      }}
                                    >
                                      <AddCircleOutlineIcon />
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          width: "100%",
                                        }}
                                      >
                                        <Typography
                                          variant="subtitle1"
                                          marginLeft="0.5rem"
                                        >
                                          Credit or Debit card
                                        </Typography>
                                      </div>
                                    </Box>
                                  </MenuItem>
                                  {givexCardExist === true && (
                                    <MenuItem>
                                      <Box
                                        onClick={handleSplitPaymentChange}
                                        sx={{
                                          display: "flex",
                                          marginBottom: "0.5rem",
                                          marginTop: "0.5rem",
                                          width: "100%",
                                        }}
                                      >
                                        <AddCircleOutlineIcon />
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            width: "100%",
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle1"
                                            marginLeft="0.5rem"
                                          >
                                            Gift card
                                          </Typography>
                                        </div>
                                      </Box>
                                    </MenuItem>
                                  )}
                                </Select>
                              </FormControl>
                            )}
                            {showText ? (
                              <Typography className={newClasses.error}>
                                Please select card to checkout
                              </Typography>
                            ) : (
                              <p>{/* <br /> */}</p>
                            )}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: ".6rem",
                              }}
                            >
                              <Button
                                fullWidth
                                style={{
                                  marginTop: "1rem",
                                  marginBottom: "1rem",
                                  borderRadius: 20,
                                  padding: "0.6rem 5rem",
                                  backgroundColor: checkOutbuttonDisabled
                                    ? "gray"
                                    : "#C60734",
                                  color: "white",
                                  textTransform: "none",
                                }}
                                sx={{
                                  width: {
                                    xs: "auto",
                                    sm: "100%"
                                  }
                                }}
                                variant="contained"
                                onClick={(e) => {
                                  if (checkOutbuttonDisabled === false) {
                                    handleCheckOut(e);
                                  }
                                }}
                              >
                                {`Checkout $${totalAmount >= 0
                                    ? numberWithCommas(
                                      Number(totalAmount).toFixed(2)
                                    )
                                    : ""
                                  } `}
                              </Button>
                            </div>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </MyCard>
              </Container>
            </>
          )}
        </div>
      </div>
      <Login showAd={showAd} setShowAd={setShowAd}></Login>
      <Guest showGuest={showGuest} setshowGuest={setshowGuest}></Guest>
      <RewardsPopup
        open={open1}
        handleClose={handleClose}
        handleOpen={handleOpen}
        showRewards={showRewards}
        setshowRewards={setshowRewards}
        setTotalFivestar={setTotalFivestar}
        totalFivestar={totalFivestar}
        disc={disc}
        totalCartItem={totalCartItem}
        fivestarDiscount={fivestarDiscount}
        setfivestarDiscount={setfivestarDiscount}
        showCombineDiscountPopup={showCombineDiscountPopup}
        setShowCombineDiscountPopup={setShowCombineDiscountPopup}
        promoApply={promoApply}
        setAutoDisc={setAutoDisc}
        applyPromo={applyPromo}
        setAlwaysPromoApply={setAlwaysPromoApply}
        alwaysPromoApply={alwaysPromoApply}
        handleRemoveFiveStarLoyalty={handleRemoveFiveStarLoyalty}
        showCombinePopUp={showCombinePopUp}
        setShowCombinePopUp={setShowCombinePopUp}
        isCouponProcessing={isCouponProcessing}
      />

      <GiftCard
        setpage={false}
        giftCard={giftCard}
        setGiftCard={setGiftCard}
        setGivexCardDetails={setGivexCardDetails}
        payment={totalAmount}
        setGiftCardAmount={setGiftCardAmount}
        giftCardPaymentDetail={giftCardPaymentDetail}
        setGiftCardPaymentDetail={setGiftCardPaymentDetail}
        boolBalanceFetched={boolBalanceFetched}
        setBoolBalanceFetched={setBoolBalanceFetched}
        title={""}
        savedCards={savedCards}
        storeMobileId={storeName?.id}
        customerId={user?.customer_id ? user?.customer_id : user?.person_id}
        customerPhone={user?.phone_number ? user?.phone_number : user.phone_no}
        customerName={user?.first_name}
      />
      {showCard && (
        <AddCard
          setGreenCheckbox={setGreenCheckbox}
          setGiftCardSelect={setGiftCardSelect}
          showCard={showCard}
          addCardFromCart="card Added from Cart"
          setshowCard={setshowCard}
          setGivxSavedCard={setGivxSavedCard}
          page="Save"
          setpage={false}
          setSavedCard={setSavedCard}
          setCartDataSave={setCartDataSave}
          setShowText={setShowText}
          setChecoutButtonDisabled={setChecoutButtonDisabled}
        ></AddCard>
      )}
      <AlertPopup
        open={remove}
        setOpen={setRemove}
        title={"pickUpLater"}
        buttonType1={true}
        buttonType2={false}
        displayTitle={
          "The pickup time that you selected is no longer available. Please select another time slot."
        }
      />
      <AlertPopup
        open={pickUpLaterPopup}
        setOpen={setPickUpLaterPopup}
        title={"paymentCondition"}
        buttonType1={true}
        buttonType2={false}
        displayTitle={
          "The pickup time that you selected is no longer available. Please select another time slot."
        }
      />
      {customizeOpen && (
        <Popup
          customizeOpen={customizeOpen}
          setCustomizeOpen={setCustomizeOpen}
          setUpdateBtnFlag={setUpdateBtnFlag}
          updateBtnFlag={updateBtnFlag}
        />
      )}
    </>
  );
};

export default Cart;
