import { Typography } from "@mui/material";
import { toast } from "react-toastify";
toast.configure();

export const capitalizeFirstLetter = (text) => {
  if (text && typeof text === "string") {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  } else {
    return text;
  }
};

export const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber) {
    phoneNumber = phoneNumber?.replace("-", "");
    return `+1(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )} ${phoneNumber.slice(6)}`;
  }
};

export const formatPhoneNumbers = (phoneNumber) => {
  if (phoneNumber) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6)}`;
  }
};

export const detectMobileDevice = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /Windows Phone/i,
  ];
  let userAgent;
  for (let i in toMatch) {
    if (!userAgent) {
      userAgent = navigator.userAgent.match(toMatch[i]);
    }
  }
  return userAgent;
};

// error Toast message for Api Fail
export const errorToastMessage = () => {
  toast.error("Oops Something Went Wrong", {
    position: toast.POSITION.TOP_RIGHT,
    pauseOnFocusLoss: false,
    autoClose: 1500,
    pauseOnHover: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
  });
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 10;
export const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,

  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },
};

export const convertToNewYorkTime = (utcTimestamp,timeZone) => {
  const date = new Date(utcTimestamp);
  return new Intl.DateTimeFormat("en-US", {
    timeZone: timeZone?timeZone:"America/New_York",
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true, // Ensures AM/PM format
  }).format(date);
};

export const uiDate = (date) => {
  let newDate = new Date().toISOString();
  let value = date?.split("T")[1]?.split(":");
  const months = [
    "all",
    "January",
    "Feburary",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let res = AmPmFormat(
    (date ? date : newDate)?.split("T")[1].split(":")[0] +
      ":" +
      (date ? date : newDate)?.split("T")[1].split(":")[1]
  );

  return (
    ((date ? date : newDate)?.split("T")[0].split("-")[1] < 10
      ? months[
          (date ? date : newDate)?.split("T")[0].split("-")[1].replace(0, "")
        ]
      : months[(date ? date : newDate)?.split("T")[0].split("-")[1]]) +
    " " +
    (date ? date : newDate)?.split("T")[0].split("-")[2] +
    ", " +
    (date ? date : newDate)?.split("T")[0].split("-")[0] +
    " " +
    res

    //  +
    // "/" +
    // (date ? date : newDate)?.split("T")[0].split("-")[0]
    // +
    // " " +
    // ("0" + (value[0] > 12 ? value[0] - 12 : value[0])).slice(-2) +
    // ":" +
    // value[1] +
    // " " +
    // (value[0] >= 12 ? "PM" : "AM")
  );
};
export const uiDateKiosk = (date) => {
  let value = date?.split("T")[1]?.split(":");

  return (
    date.split("T")[0].split("-")[1] +
    "/" +
    date.split("T")[0].split("-")[2] +
    "/" +
    date.split("T")[0].split("-")[0] +
    " " +
    (
      "0" + (value[0] == 0 ? 12 : value[0] > 12 ? value[0] - 12 : value[0])
    ).slice(-2) +
    ":" +
    value[1] +
    " " +
    (value[0] >= 12 ? "pm" : "am")
  );
};
export const handleCartItemCheck = (
  cartItem,
  custmizationSomething,
  menuItem,
  cupSizeState
) => {
  let cartCheckIndex;
  let cartItemCheck = cartItem.filter((e, index) => {
    if (
      e.id == menuItem.id &&
      e.selectedOptions.length == custmizationSomething.length &&
      e.selectedCupSize[0].serving_name == cupSizeState &&
      e.selectedOptions.filter((singleOption) =>
        custmizationSomething.find(
          (e) => e.option[0].modifier_id == singleOption.option[0].modifier_id
        )
      ).length == custmizationSomething.length
    ) {
      cartCheckIndex = index;
      return true;
    }
  });
  let sameCustomization = cartItemCheck[0]?.selectedOptions?.filter(
    (options, indexOfOption) =>
      custmizationSomething.find((modi) => {
        if (modi.option.length > 1) {
          let sameToppings = modi.option.filter((e) =>
            options.option.find(
              (element) => element.modifier_id == e.modifier_id
            )
          );
          if (sameToppings.length == modi.option.length) {
            return true;
          } else {
            return false;
          }
        } else {
          return modi.option[0].modifier_id == options.option[0].modifier_id;
        }
      })
  );
  return { sameCustomization, cartCheckIndex };
};

export const handleSugarAndIceLevel = (customizeOption, menuItem) => {
  let partition =
    100 /
    (customizeOption?.filter(
      (e) => e.modifier_group_name == "Ice Cube Level"
    )[0]?.option.length -
      1);
  let sugarPartition =
    100 /
    (customizeOption?.filter((e) => e.modifier_group_name == "Sugar Level")[0]
      ?.option.length -
      1);
  let sugarLevelIndicator = customizeOption
    ?.filter((e) => e.modifier_group_name == "Sugar Level")[0]
    ?.option?.findIndex(
      (element) =>
        element.modifier_name ==
        menuItem?.selectedOptions?.filter(
          (e) => e.modifier_group_name == "Sugar Level"
        )[0]?.option[0]?.modifier_name
    );
  let iceCubeLevelIndicator = customizeOption
    ?.filter((e) => e.modifier_group_name == "Ice Cube Level")[0]
    ?.option?.findIndex(
      (element) =>
        element.modifier_name ==
        menuItem?.selectedOptions?.filter(
          (e) => e.modifier_group_name == "Ice Cube Level"
        )[0]?.option[0]?.modifier_name
    );
  return {
    sugarLevelIndicator,
    sugarPartition,
    iceCubeLevelIndicator,
    partition,
  };
};

export const handlePriceOfSelectedModifier = (
  customizeOption,
  custmizationSomething,
  cupSizeState
) => {
  let price = 0;
  let quantity = 1;
  customizeOption.map((element, index) => {
    let selectedModifierGroup = custmizationSomething.filter(
      (item, idx) => item?.modifier_group_id == element?.modifier_group_id
    );
    let modifierGroupIndex = custmizationSomething.findIndex(
      (item) => item?.modifier_group_id == element?.modifier_group_id
    );
    selectedModifierGroup[0]?.option.map((selectedModifier, modifierIndex) => {
      let modiferDetail = element.option.filter(
        (modifier) =>
          Number(modifier.modifier_id) == Number(selectedModifier.modifier_id)
      );
      let updatedModifierPriceDetail =
        modiferDetail[0]?.modifier_group_description?.filter(
          (mgd) => mgd?.serving_name == cupSizeState
        );
      quantity =
        custmizationSomething.length > 0
          ? custmizationSomething[modifierGroupIndex]?.option[modifierIndex]
              ?.quantity
          : 0;
      price += isNaN(
        Number(
          updatedModifierPriceDetail
            ? updatedModifierPriceDetail[0]?.price * quantity
            : 0
        )
      )
        ? 0
        : Number(
            updatedModifierPriceDetail
              ? updatedModifierPriceDetail[0]?.price * quantity
              : 0
          );
    });
  });
  return price;
};

export const generateCaptch = () => {
  const fonts = ["cursive", "sans-serif", "serif", "monospace"];
  let captchaValue = "";
  let value = btoa(Math.random() * 1000000000);
  value = value.substring(0, 6);
  return value;
  let html = value.split("").map((char) => {
    const rotates = -50 + Math?.trunc(Math?.random() * 30);
    const font = Math.trunc(Math?.random()?.fonts?.length);
    return <Typography component={"span"}>{char}</Typography>;
  });
  return html;
};

export const AmPmFormat = (time) => {
  let hours = Number(time?.slice(0, 2));
  let minutes = time?.slice(3);
  if (hours >= 12) {
    hours = hours % 12;
    let hoursLength = hours.toString().length;
    if (Number(minutes) > 0) {
      let hour = Number(hours) == 0 ? "12" : hours;
      return hour + ":" + minutes + " pm";
    } else if (Number(minutes) == 0 && Number(hours) > 0) {
      return hours + ":" + minutes + " pm";
    } else {
      return 12 + ":" + minutes + " pm";
    }
  } else {
    if (Number(hours) < 10) {
      if (hours == 0) {
        return 12 + ":" + minutes + " am";
      }
      return hours + ":" + minutes + " am";
    } else {
      return time + " am";
    }
  }
};

export const TwentyFourHourFormat = (time) => {
  let y = time.slice(time.length - 2, time.length);
  let modifiedTime = time;
  let hour = "";
  let minute = "";
  if (time.length != 8) {
    modifiedTime = "0" + time;
  }
  if (y == "AM") {
    hour = modifiedTime.slice(0, 2);
    minute = modifiedTime.slice(
      modifiedTime.length - 5,
      modifiedTime.length - 2
    );
    return hour + ":" + minute;
  } else {
    hour =
      Number(modifiedTime.slice(0, 2)) + 12 == 24
        ? "12"
        : modifiedTime.slice(0, 2) + 12;
    minute = modifiedTime.slice(
      modifiedTime.length - 5,
      modifiedTime.length - 2
    );
    return hour + ":" + minute;
  }
};

export const handleStoreStartEndTimeSec = (res, timeZone) => {
  let status = [
    {
      Start: res.result[0]?.sun_s,
      Close: res.result[0]?.sun_e,
      storeDayStatus: res.result[0].sun_c,
      onLineStatus: res.result[0]?.online_order_status,
    },
    {
      Start: res.result[0]?.mon_s,
      Close: res.result[0]?.mon_e,
      storeDayStatus: res.result[0]?.mon_c,
      onLineStatus: res.result[0]?.online_order_status,
    },
    {
      Start: res.result[0]?.tue_s,
      Close: res.result[0]?.tue_e,
      storeDayStatus: res.result[0]?.tue_c,
      onLineStatus: res.result[0]?.online_order_status,
    },
    {
      Start: res.result[0]?.wed_s,
      Close: res.result[0]?.wed_e,
      storeDayStatus: res.result[0]?.wed_c,
      onLineStatus: res.result[0]?.online_order_status,
    },
    {
      Start: res.result[0]?.thu_s,
      Close: res.result[0]?.thu_e,
      storeDayStatus: res.result[0]?.thu_c,
      onLineStatus: res.result[0]?.online_order_status,
    },
    {
      Start: res.result[0]?.fri_s,
      Close: res.result[0]?.fri_e,
      storeDayStatus: res.result[0]?.fri_c,
      onLineStatus: res.result[0]?.online_order_status,
    },
    {
      Start: res.result[0]?.sat_s,
      Close: res.result[0]?.sat_e,
      storeDayStatus: res.result[0]?.sat_c,
      onLineStatus: res.result[0]?.online_order_status,
    },
  ];
  let timeZoneDate = new Date().toLocaleString("en-US", {
    timeZone: timeZone,
  });
  let timeZoneDateHours =
    new Date(timeZoneDate)?.getHours() > 9
      ? new Date(timeZoneDate)?.getHours()
      : "0" + new Date(timeZoneDate)?.getHours();
  let timeZoneDateMinutes =
    new Date(timeZoneDate)?.getMinutes() > 9
      ? new Date(timeZoneDate)?.getMinutes()
      : "0" + new Date(timeZoneDate)?.getMinutes();
  let timeZoneWeekDay = new Date(timeZoneDate)?.getDay();
  let storeCurrentTime = timeZoneDateHours + ":" + timeZoneDateMinutes;
  let timeRange =
    storeCurrentTime >= status[timeZoneWeekDay]?.Start?.slice(0, 5) &&
    status[timeZoneWeekDay]?.Close?.slice(0, 5) >= storeCurrentTime;

  let storeActiveStatus = res.result[0]?.status;
  let statusOnMobile = res.result[0]?.is_active;
  return {
    status,
    timeRange,
    timeZoneWeekDay,
    storeActiveStatus,
    statusOnMobile,
  };
};

export const handleStoreStartEndTime = (res, timeZone) => {
  let status = [
    {
      Start: res.open_hrs?.sun_s,
      Close: res.close_hrs?.sun_e,
      storeDayStatus: res?.closed?.sun_c,
      onLineStatus: res?.online_order_status,
    },
    {
      Start: res.open_hrs?.mon_s,
      Close: res.close_hrs?.mon_e,
      storeDayStatus: res?.closed?.mon_c,
      onLineStatus: res?.online_order_status,
    },
    {
      Start: res.open_hrs?.tue_s,
      Close: res.close_hrs?.tue_e,
      storeDayStatus: res?.closed?.tue_c,
      onLineStatus:res?.online_order_status,
    },
    {
      Start: res.open_hrs?.wed_s,
      Close: res.close_hrs?.wed_e,
      storeDayStatus: res?.closed?.wed_c,
      onLineStatus: res?.online_order_status,
    },
    {
      Start: res.open_hrs?.thu_s,
      Close: res.close_hrs?.thu_e,
      storeDayStatus: res?.closed?.thu_c,
      onLineStatus: res?.online_order_status,
    },
    {
      Start: res.open_hrs?.fri_s,
      Close: res.close_hrs?.fri_e,
      storeDayStatus: res?.closed?.fri_c,
      onLineStatus: res?.online_order_status,
    },
    {
      Start: res.open_hrs?.sat_s,
      Close: res.close_hrs?.sat_e,
      storeDayStatus: res?.closed?.sat_c,
      onLineStatus: res?.online_order_status,
    },
  ];
  let timeZoneDate = new Date().toLocaleString("en-US", {
    timeZone: timeZone,
  });
  let timeZoneDateHours =
    new Date(timeZoneDate)?.getHours() > 9
      ? new Date(timeZoneDate)?.getHours()
      : "0" + new Date(timeZoneDate)?.getHours();
  let timeZoneDateMinutes =
    new Date(timeZoneDate)?.getMinutes() > 9
      ? new Date(timeZoneDate)?.getMinutes()
      : "0" + new Date(timeZoneDate)?.getMinutes();
  let timeZoneWeekDay = new Date(timeZoneDate)?.getDay();
  let storeCurrentTime = timeZoneDateHours + ":" + timeZoneDateMinutes;
  let timeRange =
    storeCurrentTime >= status[timeZoneWeekDay]?.Start?.slice(0, 5) &&
    status[timeZoneWeekDay]?.Close?.slice(0, 5) >= storeCurrentTime;

  let storeActiveStatus = res?.status;
  let statusOnMobile = res?.is_active;
  return {
    status,
    timeRange,
    timeZoneWeekDay,
    storeActiveStatus,
    statusOnMobile,
  };
};

export const handlePlaceOrderWithTime = (pickUpLaterTime, timeZone) => {
  let timeZoneDate = new Date().toLocaleString("en-US", { timeZone: timeZone });
  let timeZoneDateHours =
    new Date(timeZoneDate)?.getHours() > 9
      ? new Date(timeZoneDate)?.getHours()
      : "0" + new Date(timeZoneDate)?.getHours();
  let timeZoneDateMinutes =
    new Date(timeZoneDate)?.getMinutes() > 9
      ? new Date(timeZoneDate)?.getMinutes()
      : "0" + new Date(timeZoneDate)?.getMinutes();

  const storeClosingTime = pickUpLaterTime?.storeClosingTime; 
  if (storeClosingTime) {
    const [closingHours, closingMinutes] = storeClosingTime.split(':');
    
    let lastSlotTime = new Date();
    lastSlotTime.setHours(parseInt(closingHours));
    lastSlotTime.setMinutes(parseInt(closingMinutes) - 40);

    const minutes = lastSlotTime.getMinutes();
    const roundedMinutes = Math.floor(minutes / 5) * 5;
    lastSlotTime.setMinutes(roundedMinutes);

    const lastSlotHours = lastSlotTime.getHours() > 9 
      ? lastSlotTime.getHours() 
      : "0" + lastSlotTime.getHours();
    const lastSlotMinutes = lastSlotTime.getMinutes() > 9
      ? lastSlotTime.getMinutes()
      : "0" + lastSlotTime.getMinutes();

    if (timeZoneDateHours > lastSlotHours || 
       (timeZoneDateHours === lastSlotHours && timeZoneDateMinutes > lastSlotMinutes)) {
      timeZoneDateHours = lastSlotHours;
      timeZoneDateMinutes = lastSlotMinutes;
    }
  }

  return timeZoneDateHours + ":" + timeZoneDateMinutes;
};

export const handleStoreList = (storeData, item) => {
  let markedStoreIndex = storeData.findIndex(
    (ele) => ele.store_name === item.store_name
  );
  let newListOfStore = [...storeData];
  let Store = newListOfStore?.splice(markedStoreIndex, 1);
  newListOfStore?.sort((a, b) => Number(a.distance) - Number(b.distance));
  newListOfStore = [...Store, ...newListOfStore];
  return newListOfStore;
};
export const pickupTime = (time) => {
  let newTime = time?.split(":")[0] + ":" + time?.split(":")[1];
  return newTime;
};
